import { CoreAPI } from 'kiisu-api-types/core';
import { OrderNew } from 'kiisu-api-types/core.orders';
import { createRestypedAxios } from './base.api';

const coreAPI = createRestypedAxios<CoreAPI>();

export function getOrders(status?: string, limit?: number, offset?: number, etag?: string) {
  let headers = { ...coreAPI.defaults.headers };
  if (etag) {
    headers['If-None-Match'] = etag;
  }
  return coreAPI.get<'/core/v2/orders'>('/core/v2/orders', {
    params: {
      status: status,
      limit: limit,
      offset: offset
    },
    headers: headers
  });
}

export function postOrder(data: OrderNew) {
  return coreAPI.post<'/core/v2/orders'>('/core/v2/orders', data);
}

export function getOrder(id: string) {
  return coreAPI.get<'/core/v2/orders/:id'>(`/core/v2/orders/${id}`);
}

export function patchOrder(order: any) {
  return coreAPI.patch<'/core/v2/orders/:id'>(`/core/v2/orders/${order.metadata.id}`, order);
}

export function searchOrders(value: string) {
  return coreAPI.get<'/core/v2/orders/search'>('/core/v2/orders/search', {
    params: {
      value: value
    }
  });
}

export function getOrderEvents(id: string) {
  return coreAPI.get<'/core/v2/orders/:id/events'>(`/core/v2/orders/${id}/events`);
}
