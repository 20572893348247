import React from 'react';
import { Box, Text } from '@primer/react';

function DataColumn(props: { label?: string; value: string | React.ReactNode }) {
  return (
    <Box display="flex" flexDirection="column" p={2}>
      {props.label && (
        <Text fontSize={13} color={'rgb(149, 157, 165)'} fontWeight={500}>
          {props.label}
        </Text>
      )}

      <Text mt={1}>{props.value}</Text>
    </Box>
  );
}

export default DataColumn;
