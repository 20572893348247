import React, { useEffect, useState } from 'react';
import 'moment/locale/et';
import { checkAPIError } from '../services/ErrorService';
import './Orders.css';
import OrderTabView, { OrdersColumns } from '../components/orders/OrdersTabView';
import { constructOrderRow, OrderRow } from '../types/orderRow';
import ContentContainer from '../components/layout/ContentContainer';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import eventEmitter from '../utils/EventEmitter';
import { getLocations } from '../api/api.locations';
import { searchOrders } from '../api/api.orders';
import { getOrderStatuses } from '../api/api.order-statuses';
import { OrderStatusStored } from 'kiisu-api-types/core.orders';
import { LocationStored } from 'kiisu-api-types/core.locations';
import PageHead from '../components/layout/PageHead';
import CustomLoader from '../components/CustomLoader';
import OrderNavBar from '../components/orders/OrderNavBar';
import { Banner } from '@primer/react/experimental';
import ListContent from '../components/ListContent';
import { compareDates } from '../utils/DateTimeUtils';

function Orders() {
  const location = useLocation();
  const navigate = useNavigate();

  const [statuses, setStatuses] = useState({});
  const [locations, setLocations] = useState({});
  const [apiError, setApiError] = useState(undefined as string | undefined);
  const [successMsg, setSuccessMsg] = useState(undefined as string | undefined);
  const [currentStatus, setCurrentStatus] = useState(location.hash ? location.hash.slice(1) : 'pending');
  const [loading, setLoading] = useState(true);
  const [searchLoading, setSearchLoading] = useState(true);
  const [searchRows, setSearchRows] = useState<OrderRow[]>([]);
  const [searchValue, setSearchValue] = useState('');

  function handleError(error: any) {
    setApiError(checkAPIError(error));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  useEffect(() => {
    const fetchData = async () => {
      const [statusResponse, locationResponse] = await Promise.all([getOrderStatuses(), getLocations()]);
      setStatuses(
        Object.assign(
          {},
          ...statusResponse.data.items.map((status: OrderStatusStored) => ({ [status.status]: status.text }))
        )
      );
      setLocations(
        Object.assign(
          {},
          ...locationResponse.data.items.map((location: LocationStored) => ({ [location.metadata.id]: location.name }))
        )
      );
    };

    function handleSearchValueChange(data: any) {
      setSearchValue(data as string);
    }

    eventEmitter.on('ordersSearchValueChange', handleSearchValueChange);

    if (isSearchView() && !!localStorage.getItem(`ordersSearchValue`)) {
      setSearchValue(localStorage.getItem(`ordersSearchValue`) as string);
      navigate('/orders#search');
    } else if (!location.hash) {
      navigate('/orders#pending');
    }

    fetchData()
      .catch((error) => handleError(error))
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!!searchValue) {
      try {
        const debounce = setTimeout(() => {
          doSearch(searchValue);
        }, 300);
        return () => clearTimeout(debounce);
      } catch (error) {
        handleError(error);
      }
    } else {
      if (!loading) {
        navigate('/orders#pending');
      }
    }
    // eslint-disable-next-line
  }, [searchValue, loading]);

  useEffect(() => {
    handleStatusChange(location.hash.slice(1));
    // eslint-disable-next-line
  }, [window.location.href]);

  function handleStatusChange(status: string) {
    if (status !== 'search') {
      localStorage.removeItem(`ordersSearchValue`);
    }

    setCurrentStatus(status);
    setSuccessMsg(undefined);
    setApiError(undefined);
  }

  function shouldShowView(status: string) {
    return currentStatus === status && Object.keys(statuses).length > 0;
  }

  function isSearchView() {
    return location.hash === '#search';
  }

  function doSearch(input: string) {
    if (input) {
      searchOrders(input)
        .then((res) => {
          navigate('/orders#search');

          if (res.data && res.data.length > 0) {
            const rows = res.data
              .map((order) => constructOrderRow(order, locations, statuses))
              .sort((o1, o2) => compareDates(o1.inserted, o2.inserted));
            setSearchRows(rows);
          } else {
            setSearchRows([]);
          }
        })
        .catch((error) => setApiError(checkAPIError(error)))
        .finally(() => setSearchLoading(false));

      return;
    }

    navigate('/orders#pending');
    handleStatusChange('pending');
  }

  return (
    <ContentContainer xlarge>
      {apiError && (
        <Banner style={{ padding: '0.75rem 0.5rem', marginBottom: '1rem' }} variant="critical" title={apiError} />
      )}
      {successMsg && (
        <Banner style={{ padding: '0.75rem 0.5rem', marginBottom: '1rem' }} variant="success" title={successMsg} />
      )}

      <PageHead title="Tellimused" add="/orders/add" doSearch={doSearch} />
      <OrderNavBar currentStatus={currentStatus} />

      {(loading || (searchLoading && shouldShowView('search'))) && <CustomLoader />}

      {shouldShowView('pending') && !loading && (
        <OrderTabView
          status="pending"
          statuses={statuses}
          locations={locations}
          handleError={handleError}
          setSuccess={setSuccessMsg}
          orderBy="inserted"
        />
      )}

      {shouldShowView('undone') && !loading && (
        <OrderTabView
          status="undone"
          statuses={statuses}
          locations={locations}
          handleError={handleError}
          setSuccess={setSuccessMsg}
          orderBy="startedWorking"
        />
      )}

      {shouldShowView('done') && !loading && (
        <OrderTabView
          status="done"
          statuses={statuses}
          locations={locations}
          handleError={handleError}
          setSuccess={setSuccessMsg}
          orderBy="completed"
        />
      )}

      {shouldShowView('all') && !loading && (
        <OrderTabView
          status="all"
          statuses={statuses}
          locations={locations}
          handleError={handleError}
          setSuccess={setSuccessMsg}
          orderBy="inserted"
        />
      )}

      {shouldShowView('search') && !searchLoading && !loading && (
        <ListContent
          rows={searchRows}
          columns={OrdersColumns}
          blankslateText="Otsingule vastavaid tellimusi ei leitud"
        />
      )}
    </ContentContainer>
  );
}

export default Orders;
