import styled from 'styled-components';
import { sx, SxProp } from '@primer/react';

/*
Componentization of https://github.com/primer/css/blob/v19.8.2/src/utilities/typography.scss
*/

const H1 = styled.h1<SxProp>`
  font-weight: 600 !important;
  font-size: 26px !important;
  @media (min-width: 768px) {
    font-size: 32px !important;
  }
  margin-top: 0;
  margin-bottom: 0;
  ${sx};
`;

const H2 = styled.h2<SxProp>`
  font-weight: 600 !important;
  font-size: 22px !important;
  @media (min-width: 768px) {
    font-size: 24px !important;
  }
  margin-top: 0;
  margin-bottom: 0;
  ${sx};
`;

const H3 = styled.h3<SxProp>`
  font-weight: 600 !important;
  font-size: 18px !important;
  @media (min-width: 768px) {
    font-size: 20px !important;
  }
  margin-top: 0;
  margin-bottom: 0;
  ${sx};
`;

const H4 = styled.h4<SxProp>`
  font-weight: 600 !important;
  font-size: 16px !important;
  margin-top: 0;
  margin-bottom: 0;
  ${sx};
`;

const F6 = styled.span<SxProp>`
  font-size: 12px !important;
  ${sx};
`;

export { H1, H2, H3, H4, F6 };
