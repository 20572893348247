import React, { useEffect, useState } from 'react';
import ListContent from '../components/ListContent';
import ContentContainer from '../components/layout/ContentContainer';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { getLocations } from '../api/api.locations';
import { Link } from 'react-router-dom';
import { getCurrentUserRole } from '../services/CurrentUserService';
import { getEntities } from '../api/api';
import PageHead from '../components/layout/PageHead';
import CustomLoader from '../components/CustomLoader';

import { checkAPIError } from '../services/ErrorService';
import { Banner } from '@primer/react/experimental';

interface LocationRow {
  name: string;
  customer: string;
  address: string | undefined;
  phone: string | undefined;
}

function Locations() {
  useDocumentTitle(['Asukohad']);

  const columns: any[] = [
    {
      header: 'Nimetus',
      field: 'name',
      sortBy: 'alphanumeric',
      width: 'auto',
      rowHeader: true,
      renderCell: (row: any) => {
        return <Link to={'/locations/' + row.id}>{row.name}</Link>;
      }
    },
    { header: 'Klient', field: 'customer', sortBy: 'alphanumeric', hideOnSmall: true, width: 'auto' },
    { header: 'Aadress', field: 'address', width: 'auto' },
    { header: 'Telefon', field: 'phone', hideOnSmall: true, width: 'auto' }
  ];

  const [locationRows, setLocationRows] = useState(undefined as LocationRow[] | undefined);
  const [apiError, setApiError] = useState(undefined as string | undefined);
  const [loading, setLoading] = useState(true);

  function handleError(error: any) {
    setApiError(checkAPIError(error));
    if (error) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const [locations, customers] = await Promise.all([getLocations(), getEntities('customers')]);
      setLocationRows(
        locations.data.items.map((loc) => ({
          id: loc.metadata.id,
          name: loc.name,
          customer: customers.data.find((c) => c.i === loc.customerId)?.j.name,
          address: loc.address,
          phone: loc.phone
        }))
      );
    };

    fetchData()
      .catch((error) => handleError(error))
      .finally(() => setLoading(false));
  }, []);

  return (
    <ContentContainer xlarge>
      {apiError && (
        <Banner style={{ padding: '0.75rem 0.5rem', marginBottom: '1rem' }} variant="critical" title={apiError} />
      )}
      <PageHead title="Asukohad" add={getCurrentUserRole() === 'admin' ? '/locations/add' : undefined} />
      {loading && <CustomLoader />}
      {!loading && (
        <ListContent
          rows={locationRows ? locationRows : []}
          columns={columns}
          blankslateText="Ühtegi asukohta ei ole veel lisatud."
        />
      )}
    </ContentContainer>
  );
}

export default Locations;
