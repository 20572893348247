import React from 'react';
import { Box } from '@primer/react';

// https://github.com/primer/css/blob/v19.8.2/src/forms/form-control.scss#L232=
// by CSS project .form-actions class https://primer.style/css/components/forms#form-actions
// arranges buttons margins, no need to specify those in buttons
function FormFooter(props: { children: any }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 2,
        py: 2
      }}>
      {props.children}
    </Box>
  );
}

export default FormFooter;
