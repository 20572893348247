import React, { useRef } from 'react';
import { uploadFile } from '../../api/api';
import { Box, Button } from '@primer/react';
import './FileUploader.css';

function FileUploader(props: { block?: boolean; handleError: any; handleSavedFiles: any; clearErrors: any }) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const maxFileSize = 20971520;

  function triggerFileUpload() {
    props.clearErrors();
    fileInputRef.current?.click();
  }

  function onUploadClick(e: any) {
    // Need to clear target value to be able to upload same file multiple times.
    e.target.value = null;
  }

  function addAttachments(e: any) {
    // Avoid bubbling onChange, otherwise this will clear any errors that may occur with files upload.
    e.stopPropagation();
    const files: FileList | null = e.target.files;
    const filesForUpload: File[] = [];

    if (!files || files.length === 0) {
      return;
    }

    const sizeErrors: string[] = [];
    Array.from(files).forEach((file) => {
      if (file.size > maxFileSize) {
        sizeErrors.push(`Lisatud fail ${file.name} on liiga suur!`);
        return;
      }
      filesForUpload.push(file);
    });

    if (sizeErrors.length > 0) {
      props.handleError(sizeErrors.join('\n'));
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const uploads = filesForUpload.map((f) => {
      return uploadFile('attachments/upload', f);
    });

    return Promise.all(uploads)
      .then((results) => {
        const savedFiles = results.map((r) => r.data);
        props.handleSavedFiles(savedFiles);
      })
      .catch((error) => props.handleError(error));
  }

  return (
    <Box
      className="upload-container"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: props.block ? 'stretch' : 'center'
      }}>
      <Button block onClick={triggerFileUpload}>
        Lisa failid
      </Button>
      <input
        ref={fileInputRef}
        type="file"
        name="files"
        multiple
        accept=".pdf,.xlsx,.xls,.xlt,.xla,.xltx,.xlsm,image/*,video/*"
        onClick={onUploadClick}
        onChange={addAttachments}
      />
    </Box>
  );
}

export default FileUploader;

export interface SavedFile {
  destination: string;
  encoding: string;
  filename: string;
  mimetype: string;
  originalname: string;
  path: string;
  size: number;
}
