import React, { useEffect, useState } from 'react';
import { Heading, IconButton, Stack } from '@primer/react';
import SearchInput from '../SearchInput';
import { Table } from '@primer/react/experimental';
import { Link } from 'react-router-dom';
import { PlusIcon } from '@primer/octicons-react';

export interface PageHeadProps {
  title: string | JSX.Element;
  add?: string;
  doSearch?: any;
}

function PageHead(props: PageHeadProps) {
  const [searchBarGrows, setSearchBarGrows] = useState(window.innerWidth <= 543);

  function idBuilder(title: string) {
    return title.toLowerCase().split(' ').join('-');
  }

  useEffect(() => {
    function resizeEvent() {
      setSearchBarGrows(window.innerWidth <= 543);
    }

    window.addEventListener('resize', () => resizeEvent());
    return () => {
      window.removeEventListener('resize', () => resizeEvent());
    };
  }, []);

  return (
    <Stack aria-labelledby={typeof props.title === 'string' ? idBuilder(props.title) : 'order-canceled'}>
      <Stack direction="horizontal" align="center" wrap="wrap" justify="space-between">
        <Stack.Item grow={false}>
          <Heading
            sx={{ maxWidth: 'fit-content' }}
            id={typeof props.title === 'string' ? idBuilder(props.title) : 'order-canceled'}
            as="h1">
            {props.title}
          </Heading>
        </Stack.Item>
        <Stack.Item grow={true}>
          <Table.Actions>
            <Stack direction="horizontal" justify={searchBarGrows && props.doSearch ? 'space-between' : 'end'}>
              {props.doSearch && (
                <Stack.Item grow={searchBarGrows}>
                  <SearchInput doSearch={props.doSearch} />
                </Stack.Item>
              )}
              {props.add && (
                <Link to={props.add}>
                  <IconButton size="medium" aria-label="Lisa uus" icon={PlusIcon} variant="primary" />
                </Link>
              )}
            </Stack>
          </Table.Actions>
        </Stack.Item>
      </Stack>
    </Stack>
  );
}

export default PageHead;
