import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, Textarea } from '@primer/react';
import { checkAPIError } from '../../services/ErrorService';
import SingleDateTimePicker from '../SingleDateTimePicker';
import { CloseDialog } from '../../types/common';
import { Worker, WorkProgressStored } from 'kiisu-api-types/core.work-data';
import { getWorkProgress, patchWorkProgress, postWorkProgress } from '../../api/api.work-data';
import { ContainerMd } from '../layout/container';
import { Controller, useForm } from 'react-hook-form';
import { getEntitiesByReference } from '../../api/api';
import { ObjectMeta } from 'kiisu-api-types/common';
import { isAfter } from 'date-fns';
import CustomSelect from '../CustomSelect';
import FormFooter from '../FormFooter';
import { Banner } from '@primer/react/experimental';

interface FormValues {
  orderId: string;
  description?: string;
  workStart: string;
  workEnd?: string;
  workers: Worker[];
  metadata: ObjectMeta;
}

interface User {
  firstName: string;
  lastName: string;
  metadata: ObjectMeta;
}

function WorkForm(props: {
  preloadedValues?: WorkProgressStored;
  orderId: string;
  closeModal: CloseDialog;
  handleError: any;
}) {
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    Promise.all([getEntitiesByReference('users', 'role', 'worker'), getEntitiesByReference('users', 'role', 'admin')])
      .then((res) => {
        const workers = res[0].data.map((d) => ({
          firstName: d.j.firstName,
          lastName: d.j.lastName,
          metadata: {
            id: d.i,
            resourceVersion: d.v,
            creationTimestamp: d.dt
          }
        }));

        const admins = res[1].data.map((d) => ({
          firstName: d.j.firstName,
          lastName: d.j.lastName,
          metadata: {
            id: d.i,
            resourceVersion: d.v,
            creationTimestamp: d.dt
          }
        }));

        setUsers([...workers, ...admins]);
      })
      .catch((error) => props.handleError(error));
    // eslint-disable-next-line
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setError
  } = useForm<FormValues>({
    defaultValues: props.preloadedValues ? props.preloadedValues : { orderId: props.orderId }
  });

  function workEndBeforeStart(start: string, end: string): boolean {
    // Returns true if start date is after end
    return isAfter(new Date(start), new Date(end));
  }

  async function onSubmit(form: FormValues) {
    if (form.workStart && form.workEnd && workEndBeforeStart(form.workStart, form.workEnd)) {
      setError('workEnd', { message: 'Töö lõpp peab olema pärast töö algust' });
      return;
    }

    if (props.preloadedValues) {
      return patchWorkProgress(form)
        .then(() => props.closeModal(true))
        .catch((error) => props.handleError(error));
    }

    return postWorkProgress(form)
      .then(() => props.closeModal(true))
      .catch((error) => props.handleError(error));
  }

  const userOptions = users.map((user) => ({
    label: [user.firstName, user.lastName].join(' '),
    value: user.metadata.id
  }));

  function findSelectedUser(id: string): User | undefined {
    return users.find((u) => u.metadata.id === id);
  }

  function mapUserToWorker(user?: User) {
    return {
      orderId: props.orderId,
      userId: user!.metadata.id,
      firstName: user!.firstName,
      lastName: user!.lastName
    };
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} onChange={() => props.handleError(undefined)}>
      <Box sx={{ display: 'grid', gap: 3, py: 3 }}>
        <Box display="flex" justifyContent="space-between" gridGap={3}>
          <FormControl>
            <FormControl.Label>Töö algus</FormControl.Label>
            <Controller
              name="workStart"
              control={control}
              rules={{ required: { value: true, message: 'Määra töö algusaeg' } }}
              render={({ field: { onChange, value } }) => (
                <SingleDateTimePicker
                  name="workStart"
                  id="workStart"
                  value={value}
                  onChange={(val) => onChange(val?.toISOString() as string)}
                />
              )}
            />
            {errors.workStart && (
              <FormControl.Validation variant="error">{errors.workStart.message}</FormControl.Validation>
            )}
          </FormControl>

          <FormControl>
            <FormControl.Label>Töö lõpp</FormControl.Label>
            <Controller
              name="workEnd"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SingleDateTimePicker
                  name="workEnd"
                  id="workEnd"
                  value={value ? value : ''}
                  onChange={(val) => onChange(val ? val.toISOString() : undefined)}
                />
              )}
            />
            {errors.workEnd && (
              <FormControl.Validation variant="error">{errors.workEnd.message}</FormControl.Validation>
            )}
          </FormControl>
        </Box>

        <FormControl>
          <FormControl.Label htmlFor="workers-select">Töömehed</FormControl.Label>
          <Controller
            name="workers"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                inputId="workers-select"
                placeholder="Vali töömehed..."
                value={userOptions.filter((c) => (value ? value.map((v) => v.userId).includes(c.value) : undefined))}
                onChange={(val) => {
                  const workers = val.map((v) => {
                    const user = findSelectedUser(v.value);
                    return mapUserToWorker(user);
                  });
                  onChange(workers);
                }}
                options={userOptions}
                isMulti={true}
              />
            )}
          />
        </FormControl>

        <FormControl>
          <FormControl.Label>Tehtud tööd</FormControl.Label>
          <Textarea block {...register('description')} />
        </FormControl>

        <FormFooter>
          <Button onClick={() => props.closeModal(false)}>Katkesta</Button>

          <Button variant="primary" type="submit">
            {props.preloadedValues ? 'Muuda' : 'Lisa'}
          </Button>
        </FormFooter>
      </Box>
    </form>
  );
}

function WorkChangeForm(props: { id?: string; orderId: string; closeModal: CloseDialog }) {
  const [apiError, setApiError] = useState(undefined as string | undefined);
  const [entity, setEntity] = useState(undefined as WorkProgressStored | undefined);

  function handleError(error: any) {
    setApiError(checkAPIError(error));
  }

  useEffect(() => {
    if (props.id) {
      getWorkProgress(props.id)
        .then((res) => setEntity(res.data))
        .catch((error) => handleError(checkAPIError(error)));
    }
  }, [props.id]);

  return (
    <Box as="main">
      <ContainerMd sx={{ px: [16, 24, 32], my: 3 }}>
        {apiError && <Banner style={{ padding: '0.75rem 0.5rem' }} variant="critical" title={apiError} />}
        {(!props.id || entity) && (
          <WorkForm
            preloadedValues={entity}
            orderId={props.orderId}
            closeModal={props.closeModal}
            handleError={handleError}
          />
        )}
      </ContainerMd>
    </Box>
  );
}

export default WorkChangeForm;
