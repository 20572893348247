import React, { useEffect, useState } from 'react';
import { getAlarms } from '../api/api';
import { checkAPIError } from '../services/ErrorService';
import ContentContainer from '../components/layout/ContentContainer';
import Moment from 'react-moment';
import { AlarmStored } from 'kiisu-api-types/devices';
import { Octicon, Tooltip } from '@primer/react';
import { AlertIcon } from '@primer/octicons-react';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import PageHead from '../components/layout/PageHead';
import ListContent from '../components/ListContent';

import CustomLoader from '../components/CustomLoader';
import { Banner } from '@primer/react/experimental';

export default function Alarms() {
  useDocumentTitle(['Alarmid']);

  const [apiError, setApiError] = useState(undefined as undefined | string);
  const [entity, setEntity] = useState([] as AlarmStored[]);
  const [loading, setLoading] = useState(true);

  function handleError(error: any) {
    setApiError(checkAPIError(error));
    if (error) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  useEffect(() => {
    getAlarms()
      .then((res) => setEntity(res.data.items))
      .catch((error) => handleError(checkAPIError(error)))
      .finally(() => setLoading(false));
  }, []);

  const columns = [
    {
      header: 'Nimi seadmest',
      field: 'name',
      sortBy: 'alphanumeric',
      rowHeader: true,
      width: 'auto'
    },
    {
      header: 'Kontroller',
      field: 'deviceName',
      sortBy: 'alphanumeric',
      width: 'auto'
    },
    {
      header: 'Aadress',
      field: 'deviceAddress',
      hideOnSmall: true,
      width: 'auto'
    },
    {
      header: 'Alarmi tüüp',
      field: 'alarmType',
      sortBy: 'alphanumeric',
      width: 'auto'
    },
    {
      header: 'Sisestatud',
      field: 'entered',
      sortBy: 'datetime',
      hideOnSmall: true,
      width: 'auto'
    },
    {
      header: 'Toimumise aeg',
      field: 'occurred',
      sortBy: 'datetime',
      hideOnSmall: true,
      width: 'auto'
    },
    {
      header: 'Staatus',
      field: 'status',
      sortBy: 'alphanumeric',
      width: 'auto'
    },
    {
      header: 'Klaaritud',
      field: 'cleared',
      sortBy: 'datetime',
      hideOnSmall: true,
      width: 'auto'
    }
  ];

  return (
    <ContentContainer xlarge>
      {apiError && (
        <Banner style={{ padding: '0.75rem 0.5rem', marginBottom: '1rem' }} variant="critical" title={apiError} />
      )}
      <PageHead title="Alarmid" />
      {loading && <CustomLoader />}
      {!loading && (
        <ListContent
          columns={columns}
          rows={entity.map((row) => ({
            id: row.metadata.id,
            // kui alarmil pole locationit küljes siis teda ei kustutata ja jääb rippuma
            name: (
              <span>
                {row.storeName}{' '}
                {!row.locationId && (
                  <Tooltip aria-label="Nimi seadmes ja siin ei ühti! See alarm võib jääda siia rippuma!">
                    <Octicon icon={AlertIcon} />
                  </Tooltip>
                )}
              </span>
            ),
            deviceName: row.deviceName,
            deviceAddress: row.address,
            // @ts-ignore
            alarmType: row.alarmTypeName || row.typeName,
            entered: <Moment format="DD.MM.YYYY HH:mm">{row.metadata.creationTimestamp}</Moment>,
            occurred: <Moment format="DD.MM.YYYY HH:mm">{row.occurred}</Moment>,
            status: row.status,
            cleared: row.cleared ? <Moment format="DD.MM.YYYY HH:mm">{row.cleared}</Moment> : ''
          }))}
          blankslateText="Ühtegi alarmi ei ole veel lisatud."
        />
      )}
    </ContentContainer>
  );
}
