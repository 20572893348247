import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { Box, Button, FormControl, IconButton, TextInput } from '@primer/react';
import CustomSelect from '../components/CustomSelect';
import { ArrowLeftIcon, PlusIcon } from '@primer/octicons-react';
import { getMarkups, postSpareGroup } from '../api/api.spares';
import { MarkupStored } from 'kiisu-api-types/core.spares';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import FormFooter from '../components/FormFooter';
import ContentContainer from '../components/layout/ContentContainer';
import PageHead from '../components/layout/PageHead';
import MarkupDialog from '../components/spares/MarkupDialog';

import { checkAPIError } from '../services/ErrorService';
import { Banner } from '@primer/react/experimental';

interface GroupFormValues {
  name: string;
  markupId: string;
}

function SpareGroupForm(props: { handleError: any }) {
  useDocumentTitle(['Varuosad', 'Varuosa grupp']);

  const [markups, setMarkups] = useState<MarkupStored[]>([]);
  const [showDialog, setShowDialog] = useState(false);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm<GroupFormValues>();

  useEffect(() => {
    getMarkups()
      .then((result) => setMarkups(result.data.items))
      .catch((error) => props.handleError(error));
    // eslint-disable-next-line
  }, []);

  async function onSubmit(form: GroupFormValues) {
    postSpareGroup(form)
      .then(() => navigate('/spares'))
      .catch((error) => props.handleError(error));
  }

  async function onMarkupAdd(event: any) {
    event.preventDefault();
    setShowDialog(true);
  }

  function closeDialog(reload?: boolean) {
    setShowDialog(false);

    if (reload) {
      getMarkups()
        .then((result) => setMarkups(result.data.items))
        .catch((error) => props.handleError(error));
    }
  }

  const markupOptions = markups.map((data) => ({
    label: data.name,
    value: data.metadata.id
  }));

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ display: 'grid', gap: 3, pt: 4 }}>
          <FormControl>
            <FormControl.Label>Nimetus</FormControl.Label>
            <TextInput block {...register('name', { required: { value: true, message: 'Määra grupi nimetus' } })} />
            {errors.name && <FormControl.Validation variant="error">{errors.name.message}</FormControl.Validation>}
          </FormControl>

          <Box display="flex" justifyContent="space-between">
            <FormControl sx={{ flex: 'auto' }}>
              <FormControl.Label htmlFor="markup-select">Juurdehindlus</FormControl.Label>
              <Controller
                name="markupId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomSelect
                    inputId="markup-select"
                    placeholder="Vali juurdehindlus..."
                    value={markupOptions.filter((c) => value === c.value)}
                    onChange={(val) => onChange(val?.value as string)}
                    options={markupOptions}
                    isClearable={true}
                  />
                )}
              />
            </FormControl>
            <IconButton
              aria-label="todo"
              onClick={(e: any) => onMarkupAdd(e)}
              icon={PlusIcon}
              sx={{
                ml: 2,
                alignSelf: 'flex-end'
              }}
            />
          </Box>

          <FormFooter>
            <Button onClick={() => navigate('/spares')} leadingVisual={ArrowLeftIcon}>
              Tagasi
            </Button>

            <Button variant="primary" type="submit">
              Lisa
            </Button>
          </FormFooter>
        </Box>
      </form>

      <MarkupDialog isOpen={showDialog} closeDialog={closeDialog} />
    </>
  );
}

function SpareGroupChangeForm() {
  const [apiError, setApiError] = useState(undefined as string | undefined);

  function handleError(error: any) {
    setApiError(checkAPIError(error));
    if (error) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  return (
    <ContentContainer>
      {apiError && (
        <Banner style={{ padding: '0.75rem 0.5rem', marginBottom: '1rem' }} variant="critical" title={apiError} />
      )}
      <PageHead title="Lisa uus varuosa grupp"></PageHead>
      <SpareGroupForm handleError={handleError} />
    </ContentContainer>
  );
}

export default SpareGroupChangeForm;
