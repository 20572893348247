import React, { useEffect, useState } from 'react';
import { ActionList, ActionMenu, Box } from '@primer/react';
import { Link, useNavigate } from 'react-router-dom';
import { StackIcon, ToolsIcon } from '@primer/octicons-react';
import { MarkupStored, SpareGroupStored } from 'kiisu-api-types/core.spares';
import { getMarkups, getSpareGroups } from '../api/api.spares';
import { checkAPIError } from '../services/ErrorService';
import SpareGroupBox from '../components/spares/SpareGroupBox';
import IntroductionBlankslate from '../components/IntroductionBlankslate';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import ContentContainer from '../components/layout/ContentContainer';

import CustomLoader from '../components/CustomLoader';
import { Banner } from '@primer/react/experimental';
import PageHead from '../components/layout/PageHead';

function Spares() {
  useDocumentTitle(['Varuosad']);

  const navigate = useNavigate();

  const [apiError, setApiError] = useState(undefined as string | undefined);
  const [spareGroups, setSpareGroups] = useState<SpareGroupStored[]>([]);
  const [markups, setMarkups] = useState<MarkupStored[]>([]);
  const [loading, setLoading] = useState(true);

  function handleError(error: any) {
    setApiError(checkAPIError(error));
    if (error) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  useEffect(() => {
    Promise.all([getSpareGroups(), getMarkups()])
      .then(([groupsRes, markupsRes]) => {
        setSpareGroups(groupsRes.data.items);
        setMarkups(markupsRes.data.items);
      })
      .catch((error) => handleError(error))
      .finally(() => setLoading(false));
  }, []);

  return (
    <ContentContainer xlarge>
      {apiError && (
        <Banner style={{ padding: '0.75rem 0.5rem', marginBottom: '1rem' }} variant="critical" title={apiError} />
      )}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <PageHead title="Varuosad" />

        <ActionMenu>
          <ActionMenu.Button>Lisa</ActionMenu.Button>
          <ActionMenu.Overlay>
            <ActionList>
              <ActionList.Item as={Link} to="/spares/add/group">
                <ActionList.LeadingVisual>
                  <StackIcon />
                </ActionList.LeadingVisual>
                Lisa varuosa grupp
              </ActionList.Item>
              <ActionList.Item as={Link} to="/spares/add/spare">
                <ActionList.LeadingVisual>
                  <ToolsIcon />
                </ActionList.LeadingVisual>
                Lisa varuosa
              </ActionList.Item>
            </ActionList>
          </ActionMenu.Overlay>
        </ActionMenu>
      </Box>

      {spareGroups.length > 0 && (
        <Box sx={{ display: 'grid', gap: 3, pt: 4 }}>
          {spareGroups.length > 0 &&
            spareGroups.map((group) => {
              return (
                <SpareGroupBox key={group.metadata.id} group={group} markups={markups} handleError={handleError} />
              );
            })}
        </Box>
      )}

      {loading && <CustomLoader />}

      {spareGroups.length === 0 && !apiError && !loading && (
        <IntroductionBlankslate
          heading="Ühtegi varuosa gruppi ega varuosa ei ole veel lisatud."
          description="Lisa kõigepealt varuosa grupp ja seejärel selle alla varuosad."
          buttonText="Lisa varuosa grupp"
          onActionClick={() => navigate('/spares/add/group')}
        />
      )}
    </ContentContainer>
  );
}

export default Spares;
