import React from 'react';
import { Box, themeGet } from '@primer/react';
import styled from 'styled-components';

export interface DatePickerAction {
  label: string;
  date: Date | [Date, Date];
}

function DatePickerActions(props: { onChange: any; actions: DatePickerAction[] }) {
  return (
    <Box>
      <DatePickerActionsBox>
        {props.actions.map((a, i) => (
          <ActionLink
            key={i}
            onClick={() => {
              props.onChange(a.date);
            }}>
            {a.label}
          </ActionLink>
        ))}
      </DatePickerActionsBox>
    </Box>
  );
}

export default DatePickerActions;

const DatePickerActionsBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  max-width: 480px;
  padding: 8px 16px;
  width: 100%;
  border-top-color: ${themeGet('colors.border.muted')};
  border-top-style: solid;
  border-top-width: 1px;
`;

const ActionLink = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 32px;
  &:not(:last-child)::after {
    content: '';
    width: 1px;
    height: 24px;
    background-color: ${themeGet('colors.border.subtle')};
    display: inline-block;
    margin: 8px 16px;
  }
`;
