import React, { useEffect, useState } from 'react';
import { getDevices } from '../api/api';
import { checkAPIError } from '../services/ErrorService';
import ContentContainer from '../components/layout/ContentContainer';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import PageHead from '../components/layout/PageHead';
import { getLocations } from '../api/api.locations';
import CustomLoader from '../components/CustomLoader';
import { Link } from 'react-router-dom';
import ListContent from '../components/ListContent';
import { Banner } from '@primer/react/experimental';

interface DeviceRow {
  id: string;
  name: string;
  address?: string;
  ip: string;
  status?: string;
  softwareVersion: string;
}

export default function Devices() {
  useDocumentTitle(['Seadmed']);

  const [apiError, setApiError] = useState(undefined as undefined | string);
  const [deviceRows, setDeviceRows] = useState(undefined as DeviceRow[] | undefined);
  const [loading, setLoading] = useState(true);

  function handleError(error: any) {
    setApiError(checkAPIError(error));
    if (error) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const [locations, devices] = await Promise.all([getLocations(), getDevices()]);
      setDeviceRows(
        devices.data.items.map((row) => ({
          id: row.metadata.id,
          name: row.name,
          address: locations.data.items.find((l) => l.metadata.id === row.locationId)?.address,
          ip: getIPAddress(row.intIp, row.extIp),
          status: row.status?.status,
          // @ts-ignore
          softwareVersion: row.status?.crawl?.softwareVersion
        }))
      );
    };

    fetchData()
      .catch((error) => handleError(checkAPIError(error)))
      .finally(() => setLoading(false));
  }, []);

  function getIPAddress(intIp: string, extIp: string) {
    if (intIp && extIp) {
      return intIp + '/' + extIp;
    }
    if (intIp) {
      return intIp;
    }
    return extIp;
  }

  const columns = [
    {
      header: 'Nimetus',
      field: 'name',
      sortBy: 'alphanumeric',
      width: 'auto',
      rowHeader: true,
      renderCell: (row: any) => {
        return <Link to={'/devices/' + row.id}>{row.name}</Link>;
      }
    },
    {
      header: 'Asukoht',
      field: 'address',
      sortBy: 'alphanumeric',
      width: 'auto'
    },
    {
      header: 'IP',
      field: 'ip',
      hideOnSmall: true,
      width: 'auto'
    },
    {
      header: 'Staatus',
      field: 'status',
      sortBy: 'alphanumeric',
      width: 'auto'
    },
    {
      header: 'Tarkvara',
      field: 'softwareVersion',
      hideOnSmall: true,
      width: 'auto'
    }
  ];

  return (
    <ContentContainer xlarge>
      {apiError && (
        <Banner style={{ padding: '0.75rem 0.5rem', marginBottom: '1rem' }} variant="critical" title={apiError} />
      )}
      <PageHead title="Seadmed" add="/devices/add" />
      {loading && <CustomLoader />}
      {!loading && (
        <ListContent
          columns={columns}
          rows={deviceRows ? deviceRows : []}
          blankslateText="Ühtegi seadet ei ole veel lisatud."
        />
      )}
    </ContentContainer>
  );
}
