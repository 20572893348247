import React from 'react';
import { Data } from '../../api/api';
import AttachmentGallery from '../uploads/AttachmentGallery';
import DataColumn from '../DataColumn';
import { LocationStored } from 'kiisu-api-types/core.locations';

function LocationView(props: {
  entity: LocationStored;
  customers: Data[];
  files: Data[];
  users: Data[];
  handleError: any;
}) {
  function getClientName(): string {
    const found = props.customers.find((c) => c.i === props.entity.customerId);

    if (found) {
      return found.j.name;
    }

    return '';
  }

  function getUserName(id: string) {
    const user = props.users.find((u) => u.i === id);

    if (user) {
      return `${user.j.firstName} ${user.j.lastName}`;
    }

    return '';
  }

  return (
    <>
      <DataColumn label="Klient" value={getClientName()} />

      <DataColumn label="Aadress" value={props.entity.address} />

      <DataColumn label="Telefon" value={props.entity.phone} />

      {props.entity.workers && (
        <DataColumn label="Töömehed" value={props.entity.workers.map((w: string) => getUserName(w)).join(', ')} />
      )}

      {props.files.length > 0 && (
        <AttachmentGallery files={props.files.map((f) => f.j)} handleError={props.handleError} newWindow={true} />
      )}
    </>
  );
}

export default LocationView;
