import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { ThumbnailViewMemo } from './ThubnailView';
import { Box } from '@primer/react';

export interface GalleryImage {
  blob: Blob;
  mimeType: string;
  filename: string;
  hash: string;
  dataURL: string;
  thumbnail?: any;
}

function Gallery(props: { images: GalleryImage[]; showDeleteIcon?: boolean; onClick: any; onDelete: any }) {
  const [containerWidth, setContainerWidth] = useState(0);
  const galleryElement = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onClickHandler = useCallback((file: GalleryImage) => props.onClick(file), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDeleteHandler = useCallback((file: GalleryImage) => props.onDelete(file), []);

  useLayoutEffect(() => {
    let animationFrameID: number;
    const observer = new ResizeObserver((entries) => {
      // Only rerender if width changes and changes more than 20px to avoid rerendering problems with scrollbar
      const newWidth = Math.floor(entries[0].contentRect.width) - 1;
      if (containerWidth !== newWidth && Math.abs(containerWidth - newWidth) > 20) {
        // put in an animation frame to stop "benign errors" from
        // ResizObserver https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
        animationFrameID = window.requestAnimationFrame(() => {
          setContainerWidth(newWidth);
        });
      }
    });
    if (galleryElement.current) {
      observer.observe(galleryElement.current);
    }
    return () => {
      observer.disconnect();
      window.cancelAnimationFrame(animationFrameID);
    };
  });

  // no containerWidth until after first render with refs, skip calculations and render nothing
  if (!containerWidth) return <div ref={galleryElement}>&nbsp;</div>;
  // subtract 1 pixel because the browser may round up a pixel
  let columns = 5;
  if (containerWidth >= 544) columns = 8;
  if (containerWidth >= 768) columns = 11;
  if (containerWidth >= 1012) columns = 13;
  if (containerWidth >= 1280) columns = 17;

  const flexGap = 8; // Refers to gridGap set to 2 meaning 8px
  const colWidth = (containerWidth - flexGap * columns) / columns;

  return (
    <div ref={galleryElement}>
      <Box display="flex" flexWrap="wrap" mt={3} pb={4} mx={2} gridGap={2}>
        {props.images.map((i) => (
          <ThumbnailViewMemo
            key={i.hash}
            colWidth={colWidth}
            file={i}
            showDeleteIcon={props.showDeleteIcon}
            onClick={onClickHandler}
            onDelete={onDeleteHandler}
          />
        ))}
      </Box>
    </div>
  );
}

export const MemoizedGallery = React.memo(Gallery);
