import styled from 'styled-components';
import { SxProp, themeGet } from '@primer/react';

const FilterWrapper = styled.div<SxProp>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 16px;
  width: 100%;
  @media (min-width: 768px) {
    width: ${themeGet('sizes.small')};
  }
`;

const FilterBox = styled.div<SxProp>`
  display: flex;
  width: 100%;
  @media (min-width: 544px) {
    width: 240px;
  }
`;

export { FilterWrapper, FilterBox };
