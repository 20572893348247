import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from '../pages/Login';
import UserInfoForm from '../pages/UserInfoForm';
import Orders from '../pages/Orders';
import { ErrorPage } from '../components/layout/ErrorPages';
import { hasPrivileges, isAuthenticated } from '../services/CurrentUserService';
import Locations from '../pages/Locations';
import Users from '../pages/Users';
import OrderInitialForm from '../pages/OrderInitialForm';
import Devices from '../pages/Devices';
import Customers from '../pages/Customers';
import DevicesForm from '../pages/DevicesForm';
import OrdersContainer from '../pages/OrdersContainer';
import UserForm from '../pages/UserForm';
import Header from '../components/layout/Header';
import LocationContainer from '../pages/LocationsContainer';
import Alarms from '../pages/Alarms';
import { useLocation } from 'react-router';
import Spares from '../pages/Spares';
import SpareGroupForm from '../pages/SpareGroupForm';
import SpareForm from '../pages/SpareForm';
import Reportsv2 from '../pages/Reportsv2';
import CustomerForm from '../pages/CustomerForm';

function Router() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth privileges={['view_orders']}>
            <Navigate to="/orders" replace={true} />
          </RequireAuth>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route
        path="/userinfo"
        element={
          <RequireAuth>
            <UserInfoForm />
          </RequireAuth>
        }
      />
      <Route
        path="/orders"
        element={
          <RequireAuth privileges={['view_orders']}>
            <Orders />
          </RequireAuth>
        }
      />
      <Route
        path="/orders/add"
        element={
          <RequireAuth privileges={['edit_orders']}>
            <OrderInitialForm />
          </RequireAuth>
        }
      />
      <Route
        path="/orders/:id"
        element={
          <RequireAuth privileges={['view_orders', 'edit_orders']}>
            <OrdersContainer />
          </RequireAuth>
        }
      />
      <Route
        path="/orders/:id/edit"
        element={
          <RequireAuth privileges={['edit_orders']}>
            <OrderInitialForm />
          </RequireAuth>
        }
      />
      <Route
        path="/customers"
        element={
          <RequireAuth privileges={['view_customers']}>
            <Customers />
          </RequireAuth>
        }
      />
      <Route
        path="/customers/add"
        element={
          <RequireAuth privileges={['view_customers', 'edit_customers']}>
            <CustomerForm />
          </RequireAuth>
        }
      />
      <Route
        path="/customers/:id"
        element={
          <RequireAuth privileges={['view_customers', 'edit_customers']}>
            <CustomerForm />
          </RequireAuth>
        }
      />
      <Route
        path="/locations"
        element={
          <RequireAuth privileges={['view_locations']}>
            <Locations />
          </RequireAuth>
        }
      />
      <Route
        path="/locations/add"
        element={
          <RequireAuth privileges={['view_locations', 'edit_locations']}>
            <LocationContainer />
          </RequireAuth>
        }
      />
      <Route
        path="/locations/:id"
        element={
          <RequireAuth privileges={['view_locations', 'edit_locations']}>
            <LocationContainer />
          </RequireAuth>
        }
      />
      <Route
        path="/devices"
        element={
          <RequireAuth privileges={['view_devices']}>
            <Devices />
          </RequireAuth>
        }
      />
      <Route
        path="/devices/add"
        element={
          <RequireAuth privileges={['view_devices', 'edit_devices']}>
            <DevicesForm />
          </RequireAuth>
        }
      />
      <Route
        path="/devices/:id"
        element={
          <RequireAuth privileges={['view_devices', 'edit_devices']}>
            <DevicesForm />
          </RequireAuth>
        }
      />
      <Route
        path="/alarms"
        element={
          <RequireAuth privileges={['view_alarms']}>
            <Alarms />
          </RequireAuth>
        }
      />
      <Route
        path="/users"
        element={
          <RequireAuth privileges={['view_users']}>
            <Users />
          </RequireAuth>
        }
      />
      <Route
        path="/users/add"
        element={
          <RequireAuth privileges={['view_users', 'edit_users']}>
            <UserForm />
          </RequireAuth>
        }
      />
      <Route
        path="/users/:id"
        element={
          <RequireAuth privileges={['view_users', 'edit_users']}>
            <UserForm />
          </RequireAuth>
        }
      />
      <Route
        path="/reports/v2/:type"
        element={
          <RequireAuth privileges={['view_reports']}>
            <Reportsv2 />
          </RequireAuth>
        }
      />
      <Route
        path="/spares"
        element={
          <RequireAuth privileges={['view_spares']}>
            <Spares />
          </RequireAuth>
        }
      />
      <Route
        path="/spares/add/group"
        element={
          <RequireAuth privileges={['view_spares', 'edit_spares']}>
            <SpareGroupForm />
          </RequireAuth>
        }
      />
      <Route
        path="/spares/add/spare"
        element={
          <RequireAuth privileges={['view_spares', 'edit_spares']}>
            <SpareForm />
          </RequireAuth>
        }
      />
      <Route
        path="/spares/:id"
        element={
          <RequireAuth privileges={['view_spares', 'edit_spares']}>
            <SpareForm />
          </RequireAuth>
        }
      />
      <Route
        path="*"
        element={
          <>
            <Header />
            <ErrorPage message="Sisestatud URL ei ole korrektne." />
          </>
        }></Route>
    </Routes>
  );
}

export default Router;

function RequireAuth(props: { privileges?: string[]; children: React.ReactNode }) {
  let loggedIn = isAuthenticated();
  let location = useLocation();

  if (loggedIn) {
    if (!props.privileges || hasPrivileges(props.privileges)) {
      return (
        <>
          <Header />
          {props.children}
        </>
      );
    } else {
      return (
        <>
          <Header />
          <ErrorPage message="Lehele puudub ligipääs." />
        </>
      );
    }
  } else {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
}
