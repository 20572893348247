import moment from 'moment';
import { compareDesc, parse } from 'date-fns';

export function formatDateTimeValue(date: string | undefined) {
  return date ? moment(date).format('DD.MM.YYYY HH:mm') : '';
}

export function formatDateValue(date: string) {
  return date ? moment(date).format('DD.MM.YYYY') : '';
}

export function formatTimeValue(date: string) {
  return date ? moment(date).format('HH:mm') : '';
}

export function formatDateTimeDuration(start: string, end?: string) {
  if (!end) {
    return formatDateTimeValue(start);
  }

  if (formatDateValue(start) === formatDateValue(end)) {
    return formatDateTimeValue(start) + ' - ' + formatTimeValue(end);
  }

  return formatDateTimeValue(start) + ' - ' + formatDateTimeValue(end);
}

export function compareDates(a: string | undefined, b: string | undefined): number {
  if (!a || !b) {
    return 0;
  }
  return compareDesc(parse(a, 'dd.MM.yyyy HH:mm', new Date()), parse(b, 'dd.MM.yyyy HH:mm', new Date()));
}
