import React from 'react';
import Select, { components } from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import { useTheme } from '@primer/react';
import { CheckIcon } from '@primer/octicons-react';
import { InlineOcticon } from './utilities/octicon';

function CustomSelect<Option = unknown, IsMulti extends boolean = false>(props: StateManagerProps<Option, IsMulti>) {
  const { theme } = useTheme();

  const customStyles = {
    container: (base: any) => ({
      ...base,
      width: '100%'
    }),
    control: (base: any, state: any) => ({
      ...base,
      maxWidth: '100%',
      height: props.isMulti ? 'auto' : '32px',
      minHeight: '32px',
      color: theme!.colors.fg.default,
      backgroundColor: theme!.colors.canvas.default,
      borderColor: state.isFocused
        ? theme!.colors.accent.emphasis
        : props['aria-invalid']
          ? theme!.colors.danger.emphasis
          : theme!.colors.border.default,
      borderRadius: theme!.radii[2],
      boxShadow: state.isFocused ? theme!.shadows.primer.shadow.focus : undefined,
      outline: state.isFocused ? '2px solid var(--fgColor-accent, var(--color-accent-fg, #2f81f7))' : '0 !important',
      outlineOffset: '-1px'
    }),
    valueContainer: (base: any) => ({
      ...base,
      marginLeft: '2px',
      height: props.isMulti ? 'auto' : '30px',
      minHeight: '30px',
      color: theme!.colors.fg.default,
      backgroundColor: theme!.colors.canvas.default,
      alignContent: 'center'
    }),
    input: (base: any) => ({
      ...base,
      color: theme!.colors.fg.default
    }),
    placeholder: (base: any) => ({
      ...base,
      color: theme!.colors.fg.muted
    }),
    singleValue: (base: any) => ({
      ...base,
      color: theme!.colors.fg.default
    }),
    multiValue: (base: any) => ({
      ...base,
      color: theme!.colors.fg.muted,
      backgroundColor: theme!.colors.canvas.overlay,
      borderRadius: theme!.radii[2],
      borderColor: theme!.colors.border.subtle,
      borderWidth: '1px',
      borderStyle: 'solid',
      ':hover': {
        backgroundColor: theme!.colors.neutral.muted,
        boxShadow: theme!.colors.shadow,
        color: theme!.colors.fg.default
      }
    }),
    multiValueLabel: (base: any) => ({
      ...base,
      color: 'inherit',
      backgroundColor: 'transparent'
    }),
    multiValueRemove: (base: any) => ({
      ...base,
      color: 'inherit',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      border: 0,
      borderRadius: theme!.radii[2],
      ':hover': {
        backgroundColor: theme!.colors.neutral.muted
      }
    }),
    indicatorsContainer: (base: any) => ({
      ...base,
      maxHeight: props.isMulti ? 'auto' : '30px',
      backgroundColor: theme!.colors.canvas.default,
      marginRight: '2px',
      color: theme!.colors.fg.default
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      backgroundColor: theme!.colors.border.default
    }),
    menu: (base: any) => ({
      ...base,
      color: theme!.colors.fg.default,
      backgroundColor: theme!.colors.canvas.overlay
    }),
    menuList: (base: any) => ({
      ...base,
      color: theme!.colors.fg.default,
      backgroundColor: theme!.colors.canvas.overlay
    }),
    option: (base: any, state: any) => ({
      ...base,
      color: theme!.colors.fg.default,
      borderRadius: theme!.radii[2],
      backgroundColor: state.isSelected
        ? theme!.colors.canvas.overlay
        : state.isFocus
          ? theme!.colors.accent.emphasis
          : 'transparent',
      ':hover': {
        backgroundColor: theme!.colors.accent.emphasis,
        color: theme!.colors.fg.onEmphasis
      }
    })
  };

  const CustomOption = (props: any) => {
    return (
      <components.Option {...props}>
        <InlineOcticon color={props.isSelected ? theme!.colors.fg.default : 'transparent'}>
          <CheckIcon size={16} />
        </InlineOcticon>
        {props.children}
      </components.Option>
    );
  };

  return <Select {...props} components={{ Option: CustomOption }} styles={customStyles} />;
}

export default CustomSelect;
