import { SearchIcon } from '@primer/octicons-react';
import { TextInput } from '@primer/react';
import eventEmitter from '../utils/EventEmitter';
import { useEffect, useState } from 'react';

type SearchEvent = (input: string) => any;

interface SearchInputProps {
  doSearch: SearchEvent;
}

function SearchInput(props: SearchInputProps) {
  const [value, setValue] = useState('');
  const [searchPrefix, setSearchPrefix] = useState('');

  function getPrefix() {
    // eslint-disable-next-line
    const prefix = window.location.href.match(/https?:\/\/[^\/?#]+(?:\:\d+)?\/([^\/#]+)/);
    if (prefix && prefix[1]) {
      return prefix[1];
    }
    return '';
  }

  useEffect(() => {
    setSearchPrefix(getPrefix());
    setValue(localStorage.getItem(`${searchPrefix}SearchValue`) as string);
    // eslint-disable-next-line
  }, [localStorage.getItem(`${searchPrefix}SearchValue`)]);

  return (
    <TextInput
      value={value ? value : ''}
      width="100%"
      leadingVisual={SearchIcon}
      aria-label="Search"
      onChange={(e: any) => {
        setValue(e.target.value);
        eventEmitter.emit(`${searchPrefix}SearchValueChange`, e.target.value);
        localStorage.setItem(`${searchPrefix}SearchValue`, e.target.value);
      }}
      onKeyDown={(e: any) => {
        if (e.key === 'Enter') {
          props.doSearch(value);
        }
      }}
    />
  );
}

export default SearchInput;
