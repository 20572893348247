import styled from 'styled-components';
import { themeGet } from '@primer/react';

/*
Componentization of https://github.com/primer/css/blob/v19.8.2/src/box/box.scss

https://primer.style/css/components/box
Specifically to use for https://primer.style/css/components/box#box-row
*/

const Box = styled.div`
  background-color: ${themeGet('colors.canvas.default')};
  border-color: ${themeGet('colors.border.default')};
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
`;

const BoxHeader = styled.div`
  padding: 16px;
  margin: -1px -1px 0;
  background-color: ${themeGet('colors.canvas.subtle')};
  border-color: ${themeGet('colors.border.default')};
  border-style: solid;
  border-width: 1px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;

const BoxTitle = styled.h2`
  font-size: 14px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
`;

const BoxRow = styled.li`
  padding: 16px;
  margin-top: -1px;
  list-style-type: none;
  border-top-color: ${themeGet('colors.border.muted')};
  border-top-style: solid;
  border-top-width: 1px;

  &:first-of-type {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:last-of-type {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  p {
    color: ${themeGet('colors.fg.muted')};
    margin-bottom: 0;
    margin-top: 0;
  }
`;

const BoxRowLink = styled.a`
  @media (min-width: 768px) {
    color: ${themeGet('colors.fg.default')};
    text-decoration: none;

    &:hover {
      color: ${themeGet('colors.accent.fg')};
      text-decoration: none;
    }
  }
`;

export { Box, BoxHeader, BoxTitle, BoxRow, BoxRowLink };
