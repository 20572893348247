import { UnderlineNav } from '@primer/react';
import React from 'react';

interface OrderNavBarProps {
  currentStatus: string;
}

function OrderNavBar(props: OrderNavBarProps) {
  return (
    <UnderlineNav aria-label="orders" sx={{ mb: 3, flex: 1, padding: 0 }}>
      <UnderlineNav.Item href="#pending" aria-current={props.currentStatus === 'pending' ? true : undefined}>
        Ootel
      </UnderlineNav.Item>
      <UnderlineNav.Item href="#undone" aria-current={props.currentStatus === 'undone' ? true : undefined}>
        Pooleli
      </UnderlineNav.Item>
      <UnderlineNav.Item href="#done" aria-current={props.currentStatus === 'done' ? true : undefined}>
        Tehtud
      </UnderlineNav.Item>
      <UnderlineNav.Item
        href="#all"
        aria-current={props.currentStatus === 'all' || props.currentStatus === 'search' ? true : undefined}>
        Kõik
      </UnderlineNav.Item>
    </UnderlineNav>
  );
}

export default OrderNavBar;
