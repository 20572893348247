import { MarkupStored, SpareGroupStored } from 'kiisu-api-types/core.spares';
import React from 'react';
import { Box as BorderBox, BoxHeader, BoxTitle } from '../box';
import { Box, Text } from '@primer/react';
import SparesList from './SparesList';

function SpareGroupBox(props: { group: SpareGroupStored; markups: MarkupStored[]; handleError: any }) {
  function capitalize(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  function getmarkupRate(id: string): number | undefined {
    const found = props.markups.find((g) => g.metadata.id === id);
    return found ? found.rate : undefined;
  }

  return (
    <BorderBox>
      <BoxHeader>
        <Box display="flex" justifyContent="space-between" flexWrap="nowrap">
          <Box display="flex" flex="auto" alignItems="center">
            <BoxTitle>{capitalize(props.group.name)}</BoxTitle>
          </Box>
          {props.group.markupId && (
            <Box display="flex" alignItems="center" ml={3}>
              <Text whiteSpace="nowrap">{getmarkupRate(props.group.markupId)} &#37;</Text>
            </Box>
          )}
        </Box>
      </BoxHeader>

      <SparesList group={props.group.metadata.id} handleError={props.handleError} />
    </BorderBox>
  );
}

export default SpareGroupBox;
