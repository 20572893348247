import { Changeset } from 'kiisu-api-types/core.orders';

export function findChangeByKey(diff: Changeset[], key: string): Changeset | undefined {
  return diff.find((d) => d.key === key);
}

export function getValueByKey(diff: Changeset[], key: string): string {
  const found = findChangeByKey(diff, key);

  if (found && found.value && typeof found.value === 'string') {
    return found.value;
  }

  return '';
}

export function isValueByKey(diff: Changeset[], key: string, value: string): boolean {
  const found = findChangeByKey(diff, key);

  if (found && found.value && typeof found.value === 'string') {
    return found.value === value;
  }

  return false;
}

export function isOldValueByKey(diff: Changeset[], key: string, oldValue: string): boolean {
  const found = findChangeByKey(diff, key);

  if (found && found.oldValue) {
    return found.oldValue === oldValue;
  }

  return false;
}

export function keyExists(diff: Changeset[], key: string): boolean {
  return !!findChangeByKey(diff, key);
}

export function hasValue(diff: Changeset[], key: string): boolean {
  const found = findChangeByKey(diff, key);
  return !!(found && found.value);
}
