import React from 'react';
import { isExcelFile, isPDFFile, isVideoFile } from './AttachmentGallery';
import FileSaver from 'file-saver';
import { Box, Octicon } from '@primer/react';
import { DownloadIcon, TrashIcon, XIcon } from '@primer/octicons-react';
import ReactPlayer from 'react-player';
import { GalleryImage } from './Gallery';

function FileViewer(props: { image?: GalleryImage; closeDialog: any; handleFileRemove: any }) {
  function showDocumentViewer(type: string) {
    return isPDFFile(type) || isExcelFile(type);
  }

  function showVideoViewer(type: string) {
    return isVideoFile(type);
  }

  function showImageViewer(type: string) {
    return !showDocumentViewer(type) && !showVideoViewer(type);
  }

  return (
    <>
      {props.image && showImageViewer(props.image.mimeType) && (
        <ImageViewer image={props.image} closeDialog={props.closeDialog} handleFileRemove={props.handleFileRemove} />
      )}
      {props.image && showDocumentViewer(props.image.mimeType) && (
        <DocumentViewer image={props.image} closeDialog={props.closeDialog} handleFileRemove={props.handleFileRemove} />
      )}
      {props.image && showVideoViewer(props.image.mimeType) && (
        <VideoViewer image={props.image} closeDialog={props.closeDialog} handleFileRemove={props.handleFileRemove} />
      )}
    </>
  );
}

export default FileViewer;

function PreviewPopup(props: { image: GalleryImage; closeDialog: any; handleFileRemove: any; children: any }) {
  const download = (file: GalleryImage) => {
    FileSaver.saveAs(file.blob, file.filename);
  };

  const remove = (hash: string) => {
    props.closeDialog();
    return props.handleFileRemove(hash);
  };

  return (
    <Box position="absolute" left={0} top={0} zIndex={10} width={1}>
      <Box width={[1, 0.8, 0.7, 0.7, 0.5]} mx="auto">
        <Box position="relative" width={1} pb={48} backgroundColor={'#000000'}>
          <Box position="absolute" onClick={props.closeDialog} right={0} px={3} py={2}>
            <Octicon icon={XIcon} size={32} color="white" />
          </Box>

          <Box position="absolute" right={72} px={3} py={2} onClick={() => download(props.image)}>
            <Octicon icon={DownloadIcon} size={32} color="white" />
          </Box>

          {props.handleFileRemove && (
            <Box position="absolute" left={0} px={3} py={2} onClick={() => remove(props.image.hash)}>
              <Octicon icon={TrashIcon} size={32} color="rgb(215, 58, 73)" />
            </Box>
          )}
        </Box>

        <Box position="relative" width={1} top={0} left={0} backgroundColor={'#fff'}>
          {props.children}
        </Box>
      </Box>
    </Box>
  );
}

function ImageViewer(props: { image: GalleryImage; closeDialog: any; handleFileRemove: any }) {
  const imgStyle = { width: '100%', imageOrientation: 'from-image', maxHeight: window.innerHeight - 50 };
  return (
    <PreviewPopup image={props.image} closeDialog={props.closeDialog} handleFileRemove={props.handleFileRemove}>
      <Box display="flex" justifyContent="center">
        <img src={props.image.dataURL} alt={props.image.filename} title={props.image.filename} style={imgStyle} />
      </Box>
    </PreviewPopup>
  );
}

function VideoViewer(props: { image: GalleryImage; closeDialog: any; handleFileRemove: any }) {
  return (
    <PreviewPopup image={props.image} closeDialog={props.closeDialog} handleFileRemove={props.handleFileRemove}>
      <Box display="flex" justifyContent="center">
        <ReactPlayer
          url={props.image.dataURL}
          controls={true}
          playing={true}
          pip={false}
          style={{ display: 'block' }}
        />
      </Box>
    </PreviewPopup>
  );
}

function DocumentViewer(props: { image: GalleryImage; closeDialog: any; handleFileRemove: any }) {
  const docURL: string = URL.createObjectURL(props.image.blob);

  function closeDialog() {
    URL.revokeObjectURL(docURL);
    props.closeDialog();
  }

  return (
    <PreviewPopup image={props.image} closeDialog={closeDialog} handleFileRemove={props.handleFileRemove}>
      <Box display="flex" justifyContent="center" height={window.innerHeight - 50}>
        <embed src={docURL} type={props.image.mimeType} width="100%" height="100%" />
      </Box>
    </PreviewPopup>
  );
}
