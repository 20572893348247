import styled from 'styled-components';
import { sx, themeGet } from '@primer/react';

/*
Componentization of https://github.com/primer/css/blob/v19.8.2/src/layout/container.scss
*/

const ContainerMd = styled.div`
  max-width: ${themeGet('sizes.medium')};
  margin-right: auto;
  margin-left: auto;
  ${sx};
`;

const ContainerLg = styled.div`
  max-width: ${themeGet('sizes.large')};
  margin-right: auto;
  margin-left: auto;
  ${sx};
`;

const ContainerXl = styled.div`
  max-width: ${themeGet('sizes.xlarge')};
  margin-right: auto;
  margin-left: auto;
  ${sx};
`;

export { ContainerMd, ContainerLg, ContainerXl };
