import React from 'react';
import { Box, Button, Dialog } from '@primer/react';
import FormFooter from './FormFooter';

function ConfirmDialog(props: { showConfirm: boolean; closeModal: any; onClick: any; children: any }) {
  return (
    <Dialog isOpen={props.showConfirm} onDismiss={props.closeModal}>
      <Dialog.Header>Kinnitus</Dialog.Header>
      <Box m={3}>
        {props.children}

        <FormFooter>
          <Button onClick={props.closeModal}>Ei</Button>
          <Button variant="primary" onClick={props.onClick}>
            Jah
          </Button>
        </FormFooter>
      </Box>
    </Dialog>
  );
}

export default ConfirmDialog;
