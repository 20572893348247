import React, { useEffect, useState } from 'react';
import { DateRange } from '../DateRangePicker';
import { Box } from '@primer/react';
import CustomLoader from '../CustomLoader';
import { getSalaryReport } from '../../api/api.reports';
import { SalaryRow } from 'kiisu-api-types/core.reports';
import { Blankslate, BlankslateHeading, BlankslateIcon } from '../blankslate';
import ReportBox from './ReportBox';
import ReportSummaryBox from './ReportSummaryBox';
import IntroductionBlankslate from '../IntroductionBlankslate';
import { PersonIcon } from '@primer/octicons-react';

function SalaryReportv2(props: { dateRange: DateRange; userId: string | null; summary: boolean; handleError?: any }) {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<SalaryRow[]>([]);
  const [groupedRows, setGroupedRows] = useState(Object.create(null));
  const [emptyResult, setEmptyResult] = useState(false);

  useEffect(() => {
    setEmptyResult(false);

    // If date range is not set, do not make request!
    if (!props.dateRange[0] || !props.dateRange[1]) {
      return;
    }

    const params = {
      userId: props.userId ? props.userId : undefined,
      startDate: props.dateRange[0].toISOString(),
      endDate: props.dateRange[1].toISOString()
    };

    setLoading(true);

    getSalaryReport(params)
      .then((res) => {
        setRows(res.data.items);

        if (res.data.items.length === 0) {
          setEmptyResult(true);
        } else {
          setGroupedRows(groupAndSortRows(res.data.items));
        }
      })
      .catch((error) => props.handleError(error))
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, [props.dateRange, props.userId]);

  function groupAndSortRows(input: SalaryRow[]) {
    const grouped = input.reduce((prev, current) => {
      prev[current.user.name] = prev[current.user.name] || [];
      prev[current.user.name].push(current);
      return prev;
    }, Object.create(null));

    return Object.keys(grouped)
      .sort()
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: grouped[key]
        }),
        {}
      );
  }

  return (
    <Box sx={{ display: 'grid', gap: 3, py: 3 }}>
      {loading && <CustomLoader />}

      {!loading &&
        rows.length > 0 &&
        !props.summary &&
        Object.keys(groupedRows).map((user) => <ReportBox key={user} reportType="salary" rows={groupedRows[user]} />)}

      {!loading && rows.length > 0 && props.summary && <ReportSummaryBox groupedRows={groupedRows} />}

      {!loading && emptyResult && (
        <Blankslate spacious={true}>
          <BlankslateIcon icon={PersonIcon} size={24} />
          <BlankslateHeading>Päring ei tagastanud ühtegi rida.</BlankslateHeading>
        </Blankslate>
      )}

      {!loading &&
        !emptyResult &&
        rows.length === 0 && ( // Initial loading of the report page without search parameters
          <IntroductionBlankslate
            heading="Raportit ei ole veel koostatud."
            description="Raporti koostamiseks vali kuupäevade vahemik."
          />
        )}
    </Box>
  );
}

export default SalaryReportv2;
