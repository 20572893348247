import React, { useEffect, useState } from 'react';
import { SpareStored } from 'kiisu-api-types/core.spares';
import { getSpares } from '../../api/api.spares';
import { BoxRow, BoxRowLink } from '../box';
import { Box, Label, Text } from '@primer/react';
import { Link } from 'react-router-dom';
import { H4 } from '../utilities/typography';

function SparesList(props: { group: string; handleError: any }) {
  const [spares, setSpares] = useState<SpareStored[]>([]);

  useEffect(() => {
    getSpares(props.group)
      .then((res) => setSpares(res.data.items))
      .catch((error) => props.handleError(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.group]);

  return (
    <>
      {spares.length > 0 &&
        spares.map((spare) => {
          return <SpareRow key={spare.metadata.id} spare={spare} />;
        })}
    </>
  );
}

function SpareRow(props: { spare: SpareStored }) {
  return (
    <BoxRow>
      <Box display="flex" justifyContent="space-between" flexWrap="nowrap">
        <Box display="flex" flex="auto" gridGap={2} alignItems="center" flexWrap="wrap">
          <BoxRowLink as={Link} to={`/spares/${props.spare.metadata.id}`}>
            <H4>{props.spare.name}</H4>
          </BoxRowLink>

          {props.spare.code && <Label variant="secondary">{props.spare.code}</Label>}
        </Box>

        <Box display="flex" alignItems="center" flexWrap="nowrap" ml={3} gridGap={3}>
          {props.spare.price && <Text whiteSpace="nowrap">{props.spare.price} &euro;</Text>}
          <Text>{props.spare.unit}</Text>
        </Box>
      </Box>
    </BoxRow>
  );
}

export default SparesList;
