import React from 'react';
import { Box, CircleOcticon, Text } from '@primer/react';
import { XIcon } from '@primer/octicons-react';
import './ThubnailView.css';
import { GalleryImage } from './Gallery';

function ThumbnailView(props: {
  colWidth: number;
  file: GalleryImage;
  showDeleteIcon?: boolean;
  onClick: any;
  onDelete?: any;
}) {
  const wrapperStyle = {
    minWidth: props.colWidth,
    maxWidth: (props.colWidth * 9) / 6
  };

  return (
    <Box position="relative" display="flex" flexDirection="column" style={wrapperStyle} padding={1}>
      {props.showDeleteIcon && props.onDelete && (
        <Box position="absolute" onClick={() => props.onDelete(props.file)} right={0} top={0}>
          <CircleOcticon icon={XIcon} size={24} bg="rgb(234, 74, 90)" color="white" />
        </Box>
      )}
      <img
        className="thumbnail"
        src={props.file.thumbnail}
        alt={props.file.filename}
        onClick={() => props.onClick(props.file)}
        style={{ height: props.colWidth }}
      />
      <Text className="caption" fontSize="small" whiteSpace="nowrap" style={{ width: props.colWidth }}>
        {props.file.filename}
      </Text>
    </Box>
  );
}

export const ThumbnailViewMemo = React.memo(ThumbnailView);
