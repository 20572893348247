import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, TextInput } from '@primer/react';
import { checkAPIError } from '../../services/ErrorService';
import { useForm } from 'react-hook-form';
import { ContainerMd } from '../layout/container';
import { CloseDialog } from '../../types/common';
import { ObjectMeta } from 'kiisu-api-types/common';
import { StockItemStored } from 'kiisu-api-types/core.work-data';
import { getStockItem, patchStockItem, postStockItem } from '../../api/api.work-data';
import FormFooter from '../FormFooter';
import { Banner } from '@primer/react/experimental';

interface FormValues {
  orderId: string;
  code?: string;
  name: string;
  amount: number;
  unit: string;
  metadata: ObjectMeta;
}

function StockForm(props: {
  preloadedValues?: StockItemStored;
  orderId: string;
  closeModal: CloseDialog;
  handleError: any;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>({
    defaultValues: props.preloadedValues ? props.preloadedValues : { orderId: props.orderId }
  });

  async function onSubmit(form: FormValues) {
    if (form.amount) {
      form.amount = Number(form.amount?.toString().replace(/,/g, '.'));
    }

    if (props.preloadedValues) {
      return patchStockItem(form)
        .then(() => props.closeModal(true))
        .catch((error) => props.handleError(error));
    }

    return postStockItem(form)
      .then(() => props.closeModal(true))
      .catch((error) => props.handleError(error));
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} onChange={() => props.handleError(undefined)}>
      <Box sx={{ display: 'grid', gap: 3, py: 3 }}>
        <FormControl>
          <FormControl.Label>Kood</FormControl.Label>
          <TextInput block {...register('code')} />
        </FormControl>

        <FormControl>
          <FormControl.Label>Nimetus</FormControl.Label>
          <TextInput block {...register('name', { required: { value: true, message: 'Määra materjali nimetus' } })} />
          {errors.name && <FormControl.Validation variant="error">{errors.name.message}</FormControl.Validation>}
        </FormControl>

        <Box display="flex" justifyContent="space-between" gridGap={3}>
          <FormControl>
            <FormControl.Label>Kogus</FormControl.Label>
            <TextInput
              block
              {...register('amount', {
                required: { value: true, message: 'Määra materjali kogus' },
                pattern: {
                  value: /^[0-9]*[.,]?[0-9]*$/,
                  message: 'Sisesta kogus numbrites'
                }
              })}
            />
            {errors.amount && <FormControl.Validation variant="error">{errors.amount.message}</FormControl.Validation>}
          </FormControl>

          <FormControl>
            <FormControl.Label>Ühik</FormControl.Label>
            <TextInput block {...register('unit', { required: { value: true, message: 'Määra materjali ühik' } })} />
            {errors.unit && <FormControl.Validation variant="error">{errors.unit.message}</FormControl.Validation>}
          </FormControl>
        </Box>

        <FormFooter>
          <Button onClick={() => props.closeModal(false)}>Katkesta</Button>

          <Button variant="primary" type="submit">
            {props.preloadedValues ? 'Muuda' : 'Lisa'}
          </Button>
        </FormFooter>
      </Box>
    </form>
  );
}

function StockChangeForm(props: { id?: string; orderId: string; closeModal: CloseDialog }) {
  const [apiError, setApiError] = useState(undefined as string | undefined);
  const [entity, setEntity] = useState(undefined as StockItemStored | undefined);

  function handleError(error: any) {
    setApiError(checkAPIError(error));
  }

  useEffect(() => {
    if (props.id) {
      getStockItem(props.id)
        .then((res) => setEntity(res.data))
        .catch((error) => handleError(checkAPIError(error)));
    }
  }, [props.id]);

  return (
    <Box as="main">
      <ContainerMd sx={{ px: [16, 24, 32], my: 3 }}>
        {apiError && <Banner style={{ padding: '0.75rem 0.5rem' }} variant="critical" title={apiError} />}
        {(!props.id || entity) && (
          <StockForm
            preloadedValues={entity}
            orderId={props.orderId}
            closeModal={props.closeModal}
            handleError={handleError}
          />
        )}
      </ContainerMd>
    </Box>
  );
}

export default StockChangeForm;
