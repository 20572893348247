import { useNavigate, useParams } from 'react-router';
import { Box, Button, FormControl, TextInput } from '@primer/react';
import React, { useEffect, useState } from 'react';
import { SpareGroupStored, SpareStored } from 'kiisu-api-types/core.spares';
import { Controller, useForm } from 'react-hook-form';
import { ArrowLeftIcon } from '@primer/octicons-react';
import CustomSelect from '../components/CustomSelect';
import { getSpare, getSpareGroups, patchSpare, postSpare } from '../api/api.spares';
import { checkAPIError } from '../services/ErrorService';
import { ObjectMeta } from 'kiisu-api-types/common';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import FormFooter from '../components/FormFooter';
import ContentContainer from '../components/layout/ContentContainer';
import PageHead from '../components/layout/PageHead';
import { Banner } from '@primer/react/experimental';

interface FormValues {
  groupId: string;
  code?: string;
  name: string;
  unit: string;
  price?: number;
  metadata: ObjectMeta;
}

function SpareForm(props: { preloadedValues?: FormValues; error: string | undefined; handleError: any }) {
  useDocumentTitle(['Varuosad', 'Varuosa']);

  const [groups, setGroups] = useState<SpareGroupStored[]>([]);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm<FormValues>({ defaultValues: props.preloadedValues });

  useEffect(() => {
    getSpareGroups()
      .then((res) => setGroups(res.data.items))
      .catch((error) => props.handleError(error));
    // eslint-disable-next-line
  }, []);

  async function onSubmit(form: FormValues) {
    if (form.price) {
      form.price = Number(form.price?.toString().replace(/,/g, '.'));
    }

    if (props.preloadedValues && props.preloadedValues.metadata.id) {
      patchSpare(form)
        .then(() => navigate('/spares'))
        .catch((error) => props.handleError(error));
    } else {
      postSpare(form)
        .then(() => navigate('/spares'))
        .catch((error) => props.handleError(error));
    }
  }

  const groupOptions = groups.map((group) => ({
    label: group.name,
    value: group.metadata.id
  }));

  const unitOptions = units.map((unit) => ({
    label: unit.name,
    value: unit.code
  }));

  return (
    <form onSubmit={handleSubmit(onSubmit)} onChange={() => props.handleError(undefined)}>
      <Box sx={{ display: 'grid', gap: 3, pt: 4 }}>
        <FormControl>
          <FormControl.Label htmlFor="group-select">Grupp</FormControl.Label>
          <Controller
            name="groupId"
            control={control}
            rules={{ required: { value: true, message: 'Vali varuosa grupp' } }}
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                inputId="group-select"
                placeholder="Vali grupp..."
                value={groupOptions.filter((c) => value === c.value)}
                onChange={(val) => onChange(val!.value)}
                options={groupOptions}
                aria-errormessage={errors.groupId?.message}
                aria-invalid={!!errors.groupId}
              />
            )}
          />
          {errors.groupId && <FormControl.Validation variant="error">{errors.groupId.message}</FormControl.Validation>}
        </FormControl>

        <FormControl>
          <FormControl.Label>Kood</FormControl.Label>
          <TextInput block {...register('code')} />
        </FormControl>

        <FormControl>
          <FormControl.Label>Nimetus</FormControl.Label>
          <TextInput block {...register('name', { required: { value: true, message: 'Määra varuosa nimetus' } })} />
          {errors.name && <FormControl.Validation variant="error">{errors.name.message}</FormControl.Validation>}
        </FormControl>

        <FormControl>
          <FormControl.Label htmlFor="unit-select">Ühik</FormControl.Label>
          <Controller
            name="unit"
            control={control}
            rules={{ required: { value: true, message: 'Vali varuosa ühik' } }}
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                inputId="unit-select"
                placeholder="Vali ühik..."
                value={unitOptions.filter((c) => value === c.value)}
                onChange={(val) => onChange(val!.value)}
                options={unitOptions}
                aria-errormessage={errors.unit?.message}
                aria-invalid={!!errors.unit}
              />
            )}
          />
          {errors.unit && <FormControl.Validation variant="error">{errors.unit.message}</FormControl.Validation>}
        </FormControl>

        <FormControl>
          <FormControl.Label>Hind</FormControl.Label>
          <TextInput
            block
            trailingVisual="&euro;"
            {...register('price', {
              pattern: {
                value: /^[0-9]*[.,]?[0-9]*$/,
                message: 'Sisesta hind numbrites'
              }
            })}
          />
          {errors.price && <FormControl.Validation variant="error">{errors.price.message}</FormControl.Validation>}
        </FormControl>

        <FormFooter>
          <Button onClick={() => navigate('/spares')} leadingVisual={ArrowLeftIcon}>
            Tagasi
          </Button>

          <Button variant="primary" type="submit">
            {props.preloadedValues ? 'Muuda' : 'Lisa'}
          </Button>
        </FormFooter>
      </Box>
    </form>
  );
}

function SpareChangeForm() {
  const { id } = useParams();
  const [apiError, setApiError] = useState(undefined as string | undefined);
  const [entity, setEntity] = useState(undefined as SpareStored | undefined);

  function handleError(error: any) {
    setApiError(checkAPIError(error));
    if (error) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  useEffect(() => {
    if (id) {
      getSpare(id)
        .then((res) => setEntity(res.data))
        .catch((error) => handleError(error));
    }
  }, [id]);

  function dataToFormData(entity?: SpareStored) {
    if (entity) {
      return {
        groupId: entity.groupId,
        code: entity?.code,
        name: entity.name,
        unit: entity.unit,
        price: entity.price,
        metadata: entity.metadata
      };
    }
  }

  return (
    <ContentContainer>
      {apiError && (
        <Banner style={{ padding: '0.75rem 0.5rem', marginBottom: '1rem' }} variant="critical" title={apiError} />
      )}
      <PageHead title={id ? 'Muuda varuosa andmeid' : 'Lisa uus varuosa'}></PageHead>
      {!id || entity ? (
        <SpareForm preloadedValues={dataToFormData(entity)} error={apiError} handleError={handleError} />
      ) : null}
    </ContentContainer>
  );
}

export default SpareChangeForm;
// As currently has no other functional need for units from database, will leave them here.
type Unit = { code: string; name: string };

const units: Unit[] = [
  {
    code: 'tk',
    name: 'tükk'
  },
  {
    code: 'kg',
    name: 'kilogramm'
  },
  {
    code: 'g',
    name: 'gramm'
  },
  {
    code: 'l',
    name: 'liiter'
  },
  {
    code: 'ml',
    name: 'milliliiter'
  },
  {
    code: 'm',
    name: 'meeter'
  }
];
