import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, TextInput } from '@primer/react';
import { checkAPIError } from '../../services/ErrorService';
import { SpareGroupStored, SpareStored } from 'kiisu-api-types/core.spares';
import { Controller, useForm } from 'react-hook-form';
import { ContainerMd } from '../layout/container';
import { ObjectMeta } from 'kiisu-api-types/common';
import { UsedSpareStored } from 'kiisu-api-types/core.work-data';
import { getUsedSpare, patchUsedSpare, postUsedSpare } from '../../api/api.work-data';
import { CloseDialog } from '../../types/common';
import { getSpareGroups, getSpares } from '../../api/api.spares';
import CustomSelect from '../CustomSelect';
import FormFooter from '../FormFooter';
import { Banner } from '@primer/react/experimental';

interface FormValues {
  orderId: string;
  spareId: string;
  amount: number;
  metadata: ObjectMeta;
}

function UsedSpareForm(props: {
  preloadedValues?: UsedSpareStored;
  orderId: string;
  closeModal: CloseDialog;
  handleError: any;
}) {
  const [spareGroups, setSpareGroups] = useState<SpareGroupStored[]>([]);
  const [spares, setSpares] = useState<SpareStored[]>([]);

  useEffect(() => {
    Promise.all([getSpareGroups(), getSpares()])
      .then((res) => {
        setSpareGroups(res[0].data.items);
        setSpares(res[1].data.items);
      })
      .catch((error) => props.handleError(error));
    // eslint-disable-next-line
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch
  } = useForm<FormValues>({
    defaultValues: props.preloadedValues ? props.preloadedValues : { orderId: props.orderId }
  });

  const watchSpare = watch('spareId');

  async function onSubmit(form: FormValues) {
    if (form.amount) {
      form.amount = Number(form.amount?.toString().replace(/,/g, '.'));
    }

    if (props.preloadedValues) {
      return patchUsedSpare(form)
        .then(() => props.closeModal(true))
        .catch((error) => props.handleError(error));
    }

    return postUsedSpare(form)
      .then(() => props.closeModal(true))
      .catch((error) => props.handleError(error));
  }

  function getSpareUnit(): string | undefined {
    return spares.find((spare) => spare.metadata.id === watchSpare)?.unit;
  }

  const spareOptions = spareGroups.map((group) => ({
    label: group.name,
    options: spares
      .filter((spare) => spare.groupId === group.metadata.id)
      .map((spare) => ({
        label: spare.name,
        value: spare.metadata.id
      }))
  }));

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ display: 'grid', gap: 3, py: 3 }}>
        <FormControl>
          <FormControl.Label htmlFor="spare-select">Varuosa</FormControl.Label>
          <Controller
            name="spareId"
            control={control}
            rules={{ required: { value: true, message: 'Vali varuosa' } }}
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                inputId="spare-select"
                placeholder="Vali varuosa"
                value={spareOptions.flatMap((groups) => groups.options).filter((spare) => value === spare.value)}
                onChange={(val) => onChange(val!.value)}
                options={spareOptions}
                noOptionsMessage={({ inputValue }) => 'Selline valik puudub'}
                aria-errormessage={errors.spareId?.message}
                aria-invalid={!!errors.spareId}
              />
            )}
          />
          {errors.spareId && <FormControl.Validation variant="error">{errors.spareId.message}</FormControl.Validation>}
        </FormControl>

        <FormControl>
          <FormControl.Label>Kogus</FormControl.Label>
          <TextInput
            block
            trailingVisual={getSpareUnit()}
            {...register('amount', {
              required: { value: true, message: 'Määra materjali kogus' },
              pattern: {
                value: /^[0-9]*[.,]?[0-9]*$/,
                message: 'Sisesta kogus numbrites'
              }
            })}
          />
          {errors.amount && <FormControl.Validation variant="error">{errors.amount.message}</FormControl.Validation>}
        </FormControl>

        <FormFooter>
          <Button onClick={() => props.closeModal()}>Katkesta</Button>

          <Button variant="primary" type="submit">
            {props.preloadedValues ? 'Muuda' : 'Lisa'}
          </Button>
        </FormFooter>
      </Box>
    </form>
  );
}

function SpareChangeForm(props: { id?: string; orderId: string; closeModal: CloseDialog }) {
  const [apiError, setApiError] = useState(undefined as string | undefined);
  const [entity, setEntity] = useState(undefined as UsedSpareStored | undefined);

  function handleError(error: any) {
    setApiError(checkAPIError(error));
  }

  useEffect(() => {
    if (props.id) {
      getUsedSpare(props.id)
        .then((res) => setEntity(res.data))
        .catch((error) => handleError(checkAPIError(error)));
    }
  }, [props.id]);

  return (
    <Box as="main">
      <ContainerMd sx={{ px: [16, 24, 32], my: 3 }}>
        {apiError && <Banner style={{ padding: '0.75rem 0.5rem' }} variant="critical" title={apiError} />}
        {!props.id || entity ? (
          <UsedSpareForm
            preloadedValues={entity}
            orderId={props.orderId}
            closeModal={props.closeModal}
            handleError={handleError}
          />
        ) : null}
      </ContainerMd>
    </Box>
  );
}

export default SpareChangeForm;
