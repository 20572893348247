import { SalaryRow } from 'kiisu-api-types/core.reports';
import { Box as BorderBox, BoxRow } from '../box';
import { HoursSumInfo } from './ReportBox';
import React from 'react';
import { calculateTotalsV2 } from '../../utils/WorkhoursParser';
import { Box } from '@primer/react';
import { H4 } from '../utilities/typography';

function ReportSummaryBox(props: { groupedRows: { [key: string]: SalaryRow[] } }) {
  return (
    <BorderBox>
      {Object.keys(props.groupedRows).map((user) => (
        <TotalsBox key={user} rows={props.groupedRows[user]} />
      ))}
    </BorderBox>
  );
}

function TotalsBox(props: { rows: SalaryRow[] }) {
  const totals = calculateTotalsV2(props.rows);

  return (
    <BoxRow>
      <Box display="flex" gridGap={3} justifyContent="space-between" flexWrap="wrap">
        <H4>{props.rows[0].user.name}</H4>
        <Box display="flex" gridGap={4} alignItems="center">
          <HoursSumInfo totals={totals} />
        </Box>
      </Box>
    </BoxRow>
  );
}

export default ReportSummaryBox;
