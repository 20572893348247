import { Button, useTheme } from '@primer/react';
import { Blankslate, BlankslateAction, BlankslateHeading, BlankslateImage } from './blankslate';
import logo from '../assets/cat-icon-white.svg';
import React from 'react';

function IntroductionBlankslate(props: {
  heading: string;
  description?: string;
  buttonText?: string;
  onActionClick?: any;
}) {
  const { colorScheme } = useTheme();

  return (
    <Blankslate spacious={true}>
      <BlankslateImage
        src={logo}
        sx={{
          filter:
            colorScheme === 'dark'
              ? 'invert(67%) sepia(15%) saturate(246%) hue-rotate(171deg) brightness(86%) contrast(81%)'
              : 'invert(38%) sepia(8%) saturate(708%) hue-rotate(171deg) brightness(91%) contrast(88%)'
        }}
      />

      <BlankslateHeading>{props.heading}</BlankslateHeading>

      {props.description && <p>{props.description}</p>}

      {props.onActionClick && (
        <BlankslateAction>
          <Button variant="primary" onClick={props.onActionClick}>
            {props.buttonText}
          </Button>
        </BlankslateAction>
      )}
    </Blankslate>
  );
}

export default IntroductionBlankslate;
