import React, { useState } from 'react';
import { Box, Button, Dialog, Text } from '@primer/react';
import OrderDataComponent from './OrderDataComponent';
import FormFooter from '../FormFooter';
import { useNavigate } from 'react-router-dom';
import { getCurrentUserRole } from '../../services/CurrentUserService';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { ArrowLeftIcon } from '@primer/octicons-react';
import { LocationStored } from 'kiisu-api-types/core.locations';
import { OrderStored } from 'kiisu-api-types/core.orders';
import { patchOrder } from '../../api/api.orders';
import { Data, sendEmail } from '../../api/api';

interface OrderProps {
  order: OrderStored;
  location?: LocationStored;
  customer: string;
  files: Data[];
  reloadOrder: any;
  handleError: any;
}

function OrderPreview(props: OrderProps) {
  useDocumentTitle(['Tellimused', 'Tellimus']);

  const [showAcceptConfirm, setShowAcceptConfirm] = useState(false);
  const [showRejectConfirm, setShowRejectConfirm] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const navigate = useNavigate();

  function isNewEmailOrder() {
    return props.order.fromEmail && props.order.accepted === undefined;
  }

  function isRejectedOrder() {
    return props.order.fromEmail && props.order.accepted === false;
  }

  function updateOrderData(data: any): Promise<any> {
    const order = { ...props.order, ...data };
    return patchOrder(order)
      .then(() => {
        return props.reloadOrder();
      })
      .catch((error) => props.handleError(error));
  }

  function acceptOrder(withEmail: boolean) {
    setShowAcceptConfirm(false);
    if (withEmail) {
      const emailData = {
        referenceNr: props.order.referenceNr,
        emailRef: props.order.emailRef
      };

      return sendEmail('emails/accept', emailData)
        .then(() => {
          return updateOrderData({ accepted: true });
        })
        .catch((error) => props.handleError(error));
    }

    return updateOrderData({ accepted: true });
  }

  function rejectOrder(withEmail: boolean) {
    setShowRejectConfirm(false);
    if (withEmail) {
      const emailData = {
        referenceNr: props.order.referenceNr,
        emailRef: props.order.emailRef
      };

      return sendEmail('emails/reject', emailData)
        .then(() => {
          return updateOrderData({ accepted: false });
        })
        .catch((error) => props.handleError(error));
    }

    return updateOrderData({ accepted: false });
  }

  function startWorking() {
    const data = {
      status: 'inProgress',
      startedWorking: new Date().toISOString()
    };

    return updateOrderData(data);
  }

  function cancelOrder(): Promise<any> {
    const data = {
      status: 'canceled',
      completed: new Date().toISOString()
    };

    return updateOrderData(data);
  }

  return (
    <>
      <Box p={2}>
        <OrderDataComponent
          order={props.order}
          location={props.location}
          customer={props.customer}
          files={props.files}
          handleError={props.handleError}
        />

        <FormFooter>
          <Button onClick={() => navigate(-1)} leadingVisual={ArrowLeftIcon}>
            Tagasi
          </Button>

          {getCurrentUserRole() === 'admin' && (
            <Button onClick={() => navigate(`/orders/${props.order.metadata.id}/edit`)}>Muuda</Button>
          )}

          {(isNewEmailOrder() || (!isNewEmailOrder() && isRejectedOrder())) && (
            <Button
              variant="primary"
              onClick={() => {
                setShowAcceptConfirm(true);
                setDialogIsOpen(true);
              }}>
              Aktsepteeri
            </Button>
          )}

          {isNewEmailOrder() && (
            <Button
              variant="danger"
              onClick={() => {
                setShowRejectConfirm(true);
                setDialogIsOpen(true);
              }}>
              Lükka tagasi
            </Button>
          )}

          {!isNewEmailOrder() && !isRejectedOrder() && (
            <Button variant="primary" onClick={startWorking}>
              Võta töösse
            </Button>
          )}

          {!isNewEmailOrder() && getCurrentUserRole() === 'admin' && (
            <Button variant="danger" onClick={() => cancelOrder()}>
              Tühista
            </Button>
          )}
        </FormFooter>
      </Box>

      {showAcceptConfirm && (
        <EmailConfirmationDialog
          content="Kas soovid saata automaatse e-kirja intsidendi aksepteerimiseks?"
          onClose={() => setShowAcceptConfirm(false)}
          onChoose={(withEmail) => acceptOrder(withEmail)}
          isOpen={dialogIsOpen}
          setIsOpen={setDialogIsOpen}></EmailConfirmationDialog>
      )}

      {showRejectConfirm && (
        <EmailConfirmationDialog
          content="Kas soovid saata automaatse e-kirja intsidendi tagasi lükkamiseks?"
          onClose={() => setShowRejectConfirm(false)}
          onChoose={(withEmail) => rejectOrder(withEmail)}
          isOpen={dialogIsOpen}
          setIsOpen={setDialogIsOpen}></EmailConfirmationDialog>
      )}
    </>
  );
}

function EmailConfirmationDialog(props: {
  content: string;
  onClose: () => void;
  onChoose: (withEmail: boolean) => any;
  isOpen: boolean;
  setIsOpen: any;
}) {
  function handleDismiss() {
    props.setIsOpen(false);
    props.onClose();
  }

  return (
    <Dialog
      title="E-kirja kinnitus"
      isOpen={props.isOpen}
      onDismiss={handleDismiss}
      aria-label="E-kirja saatmise kinnitus hüpikaken">
      <Box
        sx={{
          px: '3em',
          py: '1em',
          display: 'flex',
          flexDirection: 'column',
          gap: '1em'
        }}>
        <Text>{props.content}</Text>
        <Box sx={{ display: 'flex', gap: '0.5em' }}>
          <Button onClick={props.onClose}>Katkesta</Button>
          <Button onClick={() => props.onChoose(false)}>Ära saada</Button>
          <Button variant="primary" onClick={() => props.onChoose(true)}>
            Saada
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default OrderPreview;
