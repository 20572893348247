import React, { useEffect, useState } from 'react';
import { addEntity, changeEntity, Data, getEntityById } from '../api/api';
import { useNavigate } from 'react-router-dom';
import { checkAPIError } from '../services/ErrorService';
import { useForm } from 'react-hook-form';
import { Box, Button, FormControl, TextInput } from '@primer/react';
import { useParams } from 'react-router';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { H2 } from '../components/utilities/typography';
import { ArrowLeftIcon } from '@primer/octicons-react';
import FormFooter from '../components/FormFooter';
import ContentContainer from '../components/layout/ContentContainer';
import PageHead from '../components/layout/PageHead';
import { Banner } from '@primer/react/experimental';

interface FormValues {
  i: string;
  name: string;
  contract: ContractValues;
}

interface ContractValues {
  normalPrice: string;
  emergencyNormalPrice: string;
  overitmePrice: string;
  emergencyOvertimePrice: string;
  kmPrice: string;
}

function CustomerForm(props: { preloadedValues?: any; handleError: any }) {
  useDocumentTitle(['Kliendid', 'Klient']);

  const navigate = useNavigate();
  const { id } = useParams();

  async function resolvePromise(promise: Promise<any>) {
    try {
      await Promise.resolve(promise);
      navigate('/customers');
    } catch (error: any) {
      props.handleError(error);
    }
  }

  function handleLocationAddOrChange(form: FormValues) {
    Object.entries(form.contract).forEach(([key, value]) => {
      form.contract[key as keyof ContractValues] = value.replace(/,/g, '.');
    });

    if (form.i) {
      return resolvePromise(changeEntity('customers', form, form));
    }
    return resolvePromise(addEntity('customers', form));
  }

  function clearErrors() {
    props.handleError('');
  }

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>({ defaultValues: props.preloadedValues });

  return (
    <form onSubmit={handleSubmit(handleLocationAddOrChange)} onChange={clearErrors}>
      <Box sx={{ display: 'grid', gap: 4 }}>
        <FormControl>
          <FormControl.Label>Nimetus</FormControl.Label>
          <TextInput
            sx={{ width: '100%' }}
            {...register('name', {
              required: {
                value: true,
                message: 'Lisa kliendi nimi'
              }
            })}
          />
          {errors.name && <FormControl.Validation variant="error">{errors.name.message}</FormControl.Validation>}
        </FormControl>

        <H2 sx={{ my: 2 }}>Lepingu andmed</H2>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: [3, 4], mb: 2 }}>
          <FormControl sx={formControlStyles}>
            <FormControl.Label sx={labelStyles}>Plaanilised tööd tööpäeviti kella 8:00-18:00</FormControl.Label>
            <TextInput
              sx={inputStyles}
              trailingVisual="€/h"
              {...register('contract.normalPrice', { pattern: /^\d+([.,]?\d+)?$|^$/ })}
            />
            {errors.contract?.normalPrice && (
              <FormControl.Validation sx={validationStyles} variant="error">
                Tunnihind peab olema number
              </FormControl.Validation>
            )}
          </FormControl>

          <FormControl sx={formControlStyles}>
            <FormControl.Label sx={labelStyles}>Avariiväljakutsed tööpäeviti kella 8:00-18:00</FormControl.Label>
            <TextInput
              sx={inputStyles}
              trailingVisual="€/h"
              {...register('contract.emergencyNormalPrice', { pattern: /^\d+([.,]?\d+)?$|^$/ })}
            />
            {errors.contract?.emergencyNormalPrice && (
              <FormControl.Validation sx={validationStyles} variant="error">
                Tunnihind peab olema number
              </FormControl.Validation>
            )}
          </FormControl>

          <FormControl sx={formControlStyles}>
            <FormControl.Label sx={labelStyles}>
              Plaanilised tööd väljaspool tööaega 18:01-7:59 ja nädalalõpud ja riiklikud pühad
            </FormControl.Label>
            <TextInput
              sx={inputStyles}
              trailingVisual="€/h"
              {...register('contract.overitmePrice', { pattern: /^\d+([.,]?\d+)?$|^$/ })}
            />
            {errors.contract?.overitmePrice && (
              <FormControl.Validation sx={validationStyles} variant="error">
                Tunnihind peab olema number
              </FormControl.Validation>
            )}
          </FormControl>

          <FormControl sx={formControlStyles}>
            <FormControl.Label sx={labelStyles}>
              Avariiväljakutsed väljaspool tööaega 18:01-7:59 ja nädalalõpud ja riiklikud pühad
            </FormControl.Label>
            <TextInput
              sx={inputStyles}
              trailingVisual="€/h"
              {...register('contract.emergencyOvertimePrice', { pattern: /^\d+([.,]?\d+)?$|^$/ })}
            />
            {errors.contract?.emergencyOvertimePrice && (
              <FormControl.Validation sx={validationStyles} variant="error">
                Tunnihind peab olema number
              </FormControl.Validation>
            )}
          </FormControl>

          <FormControl sx={formControlStyles}>
            <FormControl.Label sx={labelStyles}>Sõidukilomeeter</FormControl.Label>
            <TextInput
              sx={inputStyles}
              trailingVisual="€/km"
              {...register('contract.kmPrice', { pattern: /^\d+([.,]?\d+)?$|^$/ })}
            />
            {errors.contract?.kmPrice && (
              <FormControl.Validation sx={validationStyles} variant="error">
                Kilomeetrihind peab olema number
              </FormControl.Validation>
            )}
          </FormControl>
        </Box>

        <FormFooter>
          <Button onClick={() => navigate('/customers')} leadingVisual={ArrowLeftIcon}>
            Tagasi
          </Button>

          <Button variant="primary" type="submit">
            {id ? 'Muuda' : 'Lisa'}
          </Button>
        </FormFooter>
      </Box>
    </form>
  );
}

function CustomerChangePage() {
  const { id } = useParams();
  const [apiError, setApiError] = useState(undefined as string | undefined);
  const [entity, setEntity] = useState(undefined as Data | undefined);

  function handleError(error: any) {
    setApiError(checkAPIError(error));
    if (error) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  useEffect(() => {
    if (id) {
      getEntityById('customers', id)
        .then((res) => setEntity(res.data))
        .catch((error) => handleError(checkAPIError(error)));
    }
  }, [id]);

  function dataToFormData(entity?: Data) {
    if (entity) {
      return {
        i: entity.i,
        v: entity.v,
        t: entity.t,
        name: entity.j.name,
        contract: entity.j.contract
      };
    }
  }

  return (
    <ContentContainer>
      {apiError && (
        <Banner style={{ padding: '0.75rem 0.5rem', marginBottom: '1rem' }} variant="critical" title={apiError} />
      )}
      <PageHead title={id ? 'Muuda kliendi andmeid' : 'Lisa uus klient'} />
      {!id || entity ? <CustomerForm preloadedValues={dataToFormData(entity)} handleError={handleError} /> : ''}
    </ContentContainer>
  );
}

export default CustomerChangePage;

const labelStyles = {
  flex: 1,
  height: ['initial', '100%'],
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center'
};

const inputStyles = {
  margin: 0,
  marginLeft: [0, 4],
  width: ['100%', '120px']
};

const formControlStyles = {
  display: 'flex',
  flexDirection: ['column', 'row'],
  alignItems: ['flex-start', 'center'],
  justifyContent: 'space-between',
  position: 'relative'
};

const validationStyles = {
  position: ['initial', 'absolute'],
  right: 0,
  bottom: '-18px'
};
