import React, { useEffect, useState } from 'react';
import { changeMyData, Data, getEntityById } from '../api/api';
import { getCurrentUserId } from '../services/CurrentUserService';
import { checkAPIError } from '../services/ErrorService';
import { Box, Button, FormControl, TextInput } from '@primer/react';
import { useForm } from 'react-hook-form';
import { ArrowLeftIcon } from '@primer/octicons-react';
import { useNavigate } from 'react-router-dom';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import FormFooter from '../components/FormFooter';
import ContentContainer from '../components/layout/ContentContainer';
import PageHead from '../components/layout/PageHead';
import { Banner } from '@primer/react/experimental';

interface FormValues {
  i: string;
  firstName: string;
  lastName: string;
  username: string;
  password: string | undefined;
  passwordConfirmation: string | undefined;
  phone: string;
}

function dataToFormData(entity?: Data) {
  if (entity) {
    return {
      i: entity.i,
      v: entity.v,
      t: entity.t,
      firstName: entity.j.firstName,
      lastName: entity.j.lastName,
      username: entity.j.username,
      password: '',
      passwordConfirmation: '',
      phone: entity.j.phone,
      role: entity.j.role
    };
  }
}

function UserInfoForm(props: { preloadedValues?: FormValues; handleError: any; setSuccessMsg: any }) {
  useDocumentTitle(['Minu andmed']);

  const navigate = useNavigate();

  const onSubmit = (form: FormValues) => {
    if (form.password) {
      if (form.password !== form.passwordConfirmation) {
        setError('passwordConfirmation', { message: 'Salasõnad ei ühti' });
        return;
      }
    } else {
      delete form.password;
    }

    delete form.passwordConfirmation;

    changeMyData(form, form)
      .then(() => {
        return Promise.resolve(getEntityById('users', form.i));
      })
      .then((res) => {
        reset(dataToFormData(res.data));
        props.setSuccessMsg('Andmed uuendatud!');
      })
      .catch((error) => props.handleError(error));
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset
  } = useForm<FormValues>({ defaultValues: props.preloadedValues });

  const clearBannerPopups = () => {
    props.handleError('');
    props.setSuccessMsg('');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} onChange={clearBannerPopups}>
      <Box sx={{ display: 'grid', gap: 4 }}>
        <FormControl>
          <FormControl.Label>Eesnimi</FormControl.Label>
          <TextInput
            block={true}
            {...register('firstName', {
              required: {
                value: true,
                message: 'Määra eesnimi'
              }
            })}
          />
          {errors.firstName && (
            <FormControl.Validation variant="error">{errors.firstName.message}</FormControl.Validation>
          )}
        </FormControl>

        <FormControl>
          <FormControl.Label>Perekonnanimi</FormControl.Label>
          <TextInput
            block={true}
            {...register('lastName', {
              required: {
                value: true,
                message: 'Määra perekonnanimi'
              }
            })}
          />
          {errors.lastName && (
            <FormControl.Validation variant="error">{errors.lastName.message}</FormControl.Validation>
          )}
        </FormControl>

        <FormControl>
          <FormControl.Label>Kasutajanimi</FormControl.Label>
          <TextInput
            block={true}
            {...register('username', {
              required: {
                value: true,
                message: 'Määra kasutajanimi'
              }
            })}
          />
          {errors.username && (
            <FormControl.Validation variant="error">{errors.username.message}</FormControl.Validation>
          )}
        </FormControl>

        <FormControl>
          <FormControl.Label>Salasõna</FormControl.Label>
          <TextInput block={true} type="password" {...register('password')} />
          {errors.password && (
            <FormControl.Validation variant="error">{errors.password.message}</FormControl.Validation>
          )}
        </FormControl>

        <FormControl>
          <FormControl.Label>Salasõna kinnitamine</FormControl.Label>
          <TextInput block={true} type="password" {...register('passwordConfirmation')} />
          {errors.passwordConfirmation && (
            <FormControl.Validation variant="error">{errors.passwordConfirmation.message}</FormControl.Validation>
          )}
        </FormControl>

        <FormControl>
          <FormControl.Label>Telefon</FormControl.Label>
          <TextInput block={true} {...register('phone')} />
        </FormControl>

        <FormFooter>
          <Button onClick={() => navigate(-1)} leadingVisual={ArrowLeftIcon}>
            Tagasi
          </Button>

          <Button variant="primary" type="submit">
            Muuda
          </Button>
        </FormFooter>
      </Box>
    </form>
  );
}

function UserInfoChangePage() {
  const [apiError, setApiError] = useState(undefined as string | undefined);
  const [successMsg, setSuccessMsg] = useState(undefined as string | undefined);
  const [entity, setEntity] = useState(undefined as Data | undefined);

  function handleError(error: any) {
    setApiError(checkAPIError(error));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  useEffect(() => {
    if (getCurrentUserId()) {
      getEntityById('users', getCurrentUserId())
        .then((res) => setEntity(res.data))
        .catch((error) => handleError(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCurrentUserId()]);

  return (
    <ContentContainer>
      <PageHead title="Minu andmed"></PageHead>
      {apiError && (
        <Banner style={{ padding: '0.75rem 0.5rem', marginBottom: '1rem' }} variant="critical" title={apiError} />
      )}
      {successMsg && (
        <Banner style={{ padding: '0.75rem 0.5rem', marginBottom: '1rem' }} variant="success" title={successMsg} />
      )}
      {entity ? (
        <UserInfoForm
          preloadedValues={dataToFormData(entity)}
          handleError={handleError}
          setSuccessMsg={setSuccessMsg}
        />
      ) : (
        <></>
      )}
    </ContentContainer>
  );
}

export default UserInfoChangePage;
