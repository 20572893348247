import React from 'react';
import { Box } from '@primer/react';
import { ContainerLg, ContainerMd, ContainerXl } from './container';
import Footer from './Footer';

function ContentContainer(props: { xlarge?: boolean; large?: boolean; children: any }) {
  function getContainer() {
    if (props.xlarge) {
      return <ContainerXl sx={{ px: [16, 24, 32], my: 6 }}>{props.children}</ContainerXl>;
    } else if (props.large) {
      return <ContainerLg sx={{ px: [16, 24, 32], my: 6 }}>{props.children}</ContainerLg>;
    } else {
      return <ContainerMd sx={{ px: [16, 24, 32], my: 6 }}>{props.children}</ContainerMd>;
    }
  }
  return (
    <Box sx={{ pb: 50 }}>
      {getContainer()}
      <Footer />
    </Box>
  );
}

export default ContentContainer;
