import React, { useEffect, useState } from 'react';
import { getLocation } from '../api/api.locations';
import { checkAPIError } from '../services/ErrorService';
import { getCurrentUserRole } from '../services/CurrentUserService';
import LocationView from '../components/locations/LocationView';
import LocationForm from '../components/locations/LocationForm';
import ContentContainer from '../components/layout/ContentContainer';
import { useParams } from 'react-router';
import { LocationStored } from 'kiisu-api-types/core.locations';
import { Data, getEntities, getEntitiesByReference } from '../api/api';
import CustomLoader from '../components/CustomLoader';
import PageHead from '../components/layout/PageHead';
import { Banner } from '@primer/react/experimental';

function LocationContainer() {
  const { id } = useParams();
  const [apiError, setApiError] = useState(undefined as string | undefined);
  const [entity, setEntity] = useState(undefined as LocationStored | undefined);
  const [customers, setCustomers] = useState(undefined as Data[] | undefined);
  const [files, setFiles] = useState<Data[]>([]);
  const [users, setUsers] = useState(undefined as Data[] | undefined);
  const [loading, setLoading] = useState(true);

  function handleError(error: any) {
    setApiError(checkAPIError(error));
    if (error) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        const [locationsResponse, filesResponse] = await Promise.all([
          getLocation(id),
          getEntitiesByReference('attachments', 'parentId', id)
        ]);
        setEntity(locationsResponse.data);
        setFiles(filesResponse.data);
      };

      fetchData().catch((error) => handleError(error));
    }
    // eslint-disable-next-line
  }, [id, !!entity, files.length]);

  useEffect(() => {
    const fetchData = async () => {
      const [usersResponse, customersResponse] = await Promise.all([getEntities('users'), getEntities('customers')]);
      setUsers(usersResponse.data);
      setCustomers(customersResponse.data);
    };

    fetchData()
      .catch((error) => handleError(error))
      .finally(() => setLoading(false));
  }, []);

  function getContainerTitle() {
    if (getCurrentUserRole() === 'admin') {
      return id ? 'Muuda asukoha andmeid' : 'Lisa uus asukoht';
    } else if (id && entity) {
      return entity.name;
    }

    return 'Asukoht';
  }

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        (!id || (entity && entity.metadata.id)) && (
          <ContentContainer>
            {apiError && (
              <Banner style={{ padding: '0.75rem 0.5rem', marginBottom: '1rem' }} variant="critical" title={apiError} />
            )}
            <PageHead title={getContainerTitle()} />
            {getCurrentUserRole() === 'admin' && (
              <LocationForm
                entity={entity}
                customers={customers ? customers : []}
                files={files}
                users={users ? users : []}
                setFiles={setFiles}
                handleError={handleError}
              />
            )}
            {entity && getCurrentUserRole() === 'worker' && (
              <LocationView
                entity={entity}
                customers={customers ? customers : []}
                files={files}
                users={users ? users : []}
                handleError={handleError}
              />
            )}
          </ContentContainer>
        )
      )}
    </>
  );
}

export default LocationContainer;
