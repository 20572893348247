import { InvoiceRow, SalaryRow } from 'kiisu-api-types/core.reports';
import { Box as BorderBox, BoxHeader, BoxRow, BoxRowLink, BoxTitle } from '../box';
import React from 'react';
import { Box, Label, Octicon, Text, Tooltip } from '@primer/react';
import { Link } from 'react-router-dom';
import { F6, H4 } from '../utilities/typography';
import { ClockIcon, LocationIcon, ToolsIcon } from '@primer/octicons-react';
import { format, parseISO } from 'date-fns';
import { calculateTotalsV2 } from '../../utils/WorkhoursParser';

function ReportBox(props: { rows: InvoiceRow[] | SalaryRow[]; reportType: 'invoice' | 'salary' }) {
  return (
    <BorderBox>
      <BoxHeader>
        {props.reportType === 'invoice' && <BoxTitle>{(props.rows[0] as InvoiceRow).customer.name}</BoxTitle>}
        {props.reportType === 'salary' && <BoxTitle>{(props.rows[0] as SalaryRow).user.name}</BoxTitle>}
      </BoxHeader>

      {props.rows.map((row: InvoiceRow | SalaryRow) => (
        <ReportRow key={`${row.id} - ${row.type}`} row={row} />
      ))}

      {props.reportType === 'salary' && <HoursSumFooter rows={props.rows as SalaryRow[]} />}
    </BorderBox>
  );
}

function ReportRow(props: { row: InvoiceRow | SalaryRow }) {
  return (
    <BoxRow>
      <Box display="flex" justifyContent="space-between" flexWrap={['wrap', 'nowrap']} gridGap={2}>
        <Box display="flex" flexDirection="column" flex="auto" gridGap={2} justifyContent="center">
          <OrderInfo orderId={props.row.orderId} referenceNr={props.row.referenceNr} location={props.row.location} />

          <TypeDescription type={props.row.type} description={props.row.description} />

          <p>
            <F6>Lisatud {format(parseISO(props.row.creationTimestamp), 'dd.MM.yyyy')}</F6>
          </p>
        </Box>

        <Box display="flex" gridGap={4} alignItems="center" flexWrap="nowrap">
          {props.row.price && <PriceInfo price={props.row.price} unit={props.row.unit} />}
          {props.row.amount && <AmountInfo amount={props.row.amount} unit={props.row.unit} />}
          {props.row.sum && <SumInfo sum={props.row.sum} />}
        </Box>
      </Box>
    </BoxRow>
  );
}

function OrderInfo(props: { orderId: string; referenceNr?: string; location: string }) {
  return (
    <Box display="flex" gridGap={2} alignItems="center">
      <BoxRowLink as={Link} to={`/orders/${props.orderId}`}>
        <H4>{props.orderId.substring(0, 8)}</H4>
      </BoxRowLink>

      {props.referenceNr && <Label>{props.referenceNr}</Label>}

      <Text>{props.location}</Text>
    </Box>
  );
}

function TypeDescription(props: { type: string; description: string }) {
  return (
    <Box display="flex" gridGap={2} alignItems="center">
      <RowTypeIcon type={props.type} />
      <p>{props.description}</p>
    </Box>
  );
}

function PriceInfo(props: { price: number; unit: string }) {
  return (
    <Box display="flex" flexDirection="column">
      <Text whiteSpace="nowrap">
        {props.price} &euro;/{props.unit}
      </Text>
    </Box>
  );
}

function AmountInfo(props: { amount: number; unit: string }) {
  return (
    <Box display="flex" flexDirection="column">
      <Text whiteSpace="nowrap">
        {props.amount} {props.unit}
      </Text>
    </Box>
  );
}

function SumInfo(props: { sum: number }) {
  return (
    <Box display="flex" flexDirection="column">
      <Text whiteSpace="nowrap">{props.sum} &euro;</Text>
    </Box>
  );
}

function HoursSumFooter(props: { rows: SalaryRow[] }) {
  const totals: SalaryTotals = calculateTotalsV2(props.rows);

  return (
    <BoxRow>
      <Box display="flex" flexWrap="nowrap" gridGap={4} justifyContent="flex-end">
        <HoursSumInfo totals={totals} />
      </Box>
    </BoxRow>
  );
}

export type SalaryTotals = { workHours: number; overHours: number; drivingHours: number };

export function HoursSumInfo(props: { totals: SalaryTotals }) {
  return (
    <>
      <Box display="flex" alignItems="center" gridGap={2} flexWrap="nowrap">
        <RowTypeIcon type="Töötunnid" />
        <Text whiteSpace="nowrap">{props.totals.workHours} h</Text>
      </Box>

      <Box display="flex" alignItems="center" gridGap={2} flexWrap="nowrap">
        <RowTypeIcon type="Ületunnid" />
        <Text whiteSpace="nowrap">{props.totals.overHours} h</Text>
      </Box>

      <Box display="flex" alignItems="center" gridGap={2} flexWrap="nowrap">
        <RowTypeIcon type="Sõidutunnid" />
        <Text whiteSpace="nowrap">{props.totals.drivingHours} h</Text>
      </Box>
    </>
  );
}

function RowTypeIcon(props: { type: string }) {
  return (
    <Tooltip aria-label={props.type} noDelay={true}>
      {(props.type === 'Materjalid' || props.type === 'Varuosad') && <Octicon icon={ToolsIcon} />}
      {(props.type === 'Sõidu km' || props.type === 'Sõidutunnid') && <Octicon icon={LocationIcon} />}
      {props.type === 'Töötunnid' && <Octicon icon={ClockIcon} />}
      {props.type === 'Ületunnid' && <Octicon icon={ClockIcon} color="attention.fg" />}
    </Tooltip>
  );
}

export default ReportBox;
