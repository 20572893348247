import React from 'react';
import { BaseStyles, themeGet, ThemeProvider } from '@primer/react';
import Router from './router/Router';
import { BrowserRouter } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider colorMode="auto">
        <MyGlobalAddon />
        <BaseStyles>
          <Router />
        </BaseStyles>
      </ThemeProvider>
    </BrowserRouter>
  );
}

const MyGlobalAddon = createGlobalStyle`
html { background-color: ${themeGet('colors.canvas.default')}; }
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji' !default;
  font-size: 0.875em;
  line-height: 1em;
  color: ${themeGet('colors.fg.default')};
  background-color: ${themeGet('colors.canvas.default')};
  min-height: 100vh;
  min-height: 100dvh; // For not losing header on iOS Safari.
}

a {
  color: ${themeGet('colors.accent.fg')};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
// Do not show toggle switch On/Off labels
#notifications-switch > span {
  display: none;
}
// DatePicker global styles for dark mode support using primer theme colors
.react-datepicker {
  color: var(--fgColor-default);
  background-color: var(--overlay-bgColor);
  border-color: var(--overlay-borderColor);
}
.react-datepicker__tab-loop {
  margin: 0;
}
.react-datepicker-wrapper {
  width: 100%;
  align-self: stretch;
}
.react-datepicker__header {
  background-color: var(--bgColor-muted);
  border-bottom-color: var(--overlay-borderColor);
}
.react-datepicker__time-container {
  border-left-color: var(--overlay-borderColor);
}
.react-datepicker__time-container .react-datepicker__time {
  background: var(--overlay-bgColor);
}
.react-datepicker * {
  color: var(--fgColor-default);
}
.react-datepicker__time-list-item--selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: var(--bgColor-accent-emphasis) !important;
  color: var(--fgColor-onEmphasis) !important;
}
.react-datepicker__day:hover, li.react-datepicker__time-list-item:hover {
  background-color: var(--control-bgColor-hover) !important;
  color: var(--fgColor-default) !important;
}
.react-datepicker__triangle {
  stroke: var(--overlay-bgColor) !important;
  fill: var(--overlay-bgColor) !important;
  color: var(--overlay-borderColor) !important;
}
.react-datepicker__day--keyboard-selected {
  background-color: var(--control-bgColor-active);
}
`;

export default App;
