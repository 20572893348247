import { useEffect } from 'react';

export type DocumentTitleTranslation = { [key: string]: string };

export const useDocumentTitle = (titleComponents: Array<string>, translation?: DocumentTitleTranslation) => {
  let titleBuilder = ['Kiisu.net', ...titleComponents];
  const url = window.location.href;

  if (translation) {
    const subheading = url.match(/#(\w+)/);
    if (subheading && subheading[1]) {
      titleBuilder.push(translation[subheading[1]]);
    }
  }

  const idx = titleBuilder.length - 1;
  if (url.includes('add')) {
    titleBuilder[idx] = 'Lisa ' + titleBuilder[idx].toLowerCase();
  } else if (url.includes('edit')) {
    titleBuilder[idx] = 'Muuda ' + titleBuilder[idx].toLowerCase();
  }

  useEffect(() => {
    document.title = titleBuilder.join(' - ');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);
};
