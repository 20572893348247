import { SalaryRow } from 'kiisu-api-types/core.reports';

export function calculateTotalsV2(rows: SalaryRow[]) {
  const totals = { workHours: 0, overHours: 0, drivingHours: 0 };

  rows.forEach((r) => {
    if (!r.amount) {
      return;
    }
    if (r.type === 'Töötunnid') {
      totals.workHours += r.amount;
    }
    if (r.type === 'Ületunnid') {
      totals.overHours += r.amount;
    }
    if (r.type === 'Sõidutunnid') {
      totals.drivingHours += r.amount;
    }
  });

  totals.workHours = roundSum(totals.workHours);
  totals.overHours = roundSum(totals.overHours);
  totals.drivingHours = roundSum(totals.drivingHours);

  return totals;
}

function roundSum(value: number) {
  return Math.round((value + Number.EPSILON) * 100) / 100;
}
