import React, { useState } from 'react';
import { Data, sendEmail } from '../../api/api';
import { Box, Button, Heading } from '@primer/react';
import OrderDataComponent from './OrderDataComponent';
import WorkProgressList from '../workData/WorkProgressList';
import FormFooter from '../FormFooter';
import { getCurrentUserRole } from '../../services/CurrentUserService';
import OrderReopenDialog from './OrderReopenDialog';
import DataColumn from '../DataColumn';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { ArrowLeftIcon } from '@primer/octicons-react';
import { useNavigate } from 'react-router-dom';
import { LocationStored } from 'kiisu-api-types/core.locations';
import { OrderStored } from 'kiisu-api-types/core.orders';
import { patchOrder } from '../../api/api.orders';

interface OrderProps {
  order: OrderStored;
  location?: LocationStored;
  customer: string;
  files: Data[];
  reloadOrder: any;
  handleError: any;
}

function OrderFullView(props: OrderProps) {
  useDocumentTitle(['Tellimused', 'Tellimus']);

  const [showConfirm, toggleConfirm] = useState(false);
  const navigate = useNavigate();

  function updateOrderData(data: any): Promise<any> {
    const order = { ...props.order, ...data };
    return patchOrder(order)
      .then(() => {
        return props.reloadOrder();
      })
      .catch((error) => props.handleError(error));
  }

  function startWorking() {
    const data = {
      status: 'inProgress'
    };

    return updateOrderData(data);
  }

  function changeOrder(): Promise<any> {
    const data = {
      status: 'inProgress',
      clientName: '',
      clientSignature: ''
    };

    return updateOrderData(data);
  }

  function completeOrder() {
    const data = {
      status: 'done',
      completed: new Date().toISOString()
    };

    return updateOrderData(data)
      .then(() => {
        if (props.order.fromEmail && props.order.referenceNr) {
          const emailData = {
            referenceNr: props.order.referenceNr,
            emailRef: props.order.emailRef
          };

          return sendEmail('emails/resolve', emailData);
        }
      })
      .catch((error) => props.handleError(error));
  }

  function cancelOrder(): Promise<any> {
    const data = {
      status: 'canceled',
      completed: new Date().toISOString()
    };

    return updateOrderData(data);
  }

  function canReopenOrder(): boolean {
    return getCurrentUserRole() === 'admin' && (props.order.status === 'done' || props.order.status === 'canceled');
  }

  return (
    <>
      <Box p={2}>
        <OrderDataComponent
          order={props.order}
          location={props.location}
          customer={props.customer}
          files={props.files}
          handleError={props.handleError}
        />

        <Box display="flex" py={3} justifyContent="space-between" alignItems="center">
          <Heading sx={{ fontSize: 4 }}>Teostatud tööd</Heading>
        </Box>

        <Box width={1} pb={3}>
          <WorkProgressList
            orderId={props.order.metadata.id}
            canChange={false}
            reload={false}
            handleError={props.handleError}
          />
        </Box>

        <Heading sx={{ fontSize: 4, py: 3 }}>Kliendi allkiri</Heading>

        <Box display="flex" flexWrap={['wrap', 'nowrap']} mt={2} justifyContent="space-between">
          <DataColumn label="Vastuvõtja" value={props.order.clientName} />
        </Box>

        <Box display="flex" flexWrap={['wrap', 'nowrap']} mt={2} justifyContent="space-between">
          <DataColumn
            label="Allkiri"
            value={
              props.order.clientSignature && (
                <div className="signature-wrapper" style={{ height: 'auto' }}>
                  <img src={props.order.clientSignature} alt="Kliendi allkiri" />
                </div>
              )
            }
          />
        </Box>

        <FormFooter>
          <Button onClick={() => navigate(-1)} leadingVisual={ArrowLeftIcon}>
            Tagasi
          </Button>

          {props.order.status === 'paused' && (
            <Button variant="primary" onClick={startWorking}>
              Võta töösse
            </Button>
          )}

          {props.order.status === 'signed' && <Button onClick={changeOrder}>Muuda</Button>}

          {props.order.status === 'signed' && (
            <Button variant="primary" type="submit" onClick={completeOrder}>
              Lõpeta
            </Button>
          )}

          {!canReopenOrder() && getCurrentUserRole() === 'admin' && (
            <Button variant="danger" onClick={() => cancelOrder()}>
              Tühista
            </Button>
          )}

          {canReopenOrder() && (
            <Button variant="danger" onClick={() => toggleConfirm(true)}>
              Ava uuesti
            </Button>
          )}
        </FormFooter>
      </Box>

      {canReopenOrder() && (
        <OrderReopenDialog
          showConfirm={showConfirm}
          toggleConfirm={toggleConfirm}
          order={props.order}
          reloadOrder={props.reloadOrder}
          handleError={props.handleError}
        />
      )}
    </>
  );
}

export default OrderFullView;
