import React, { useEffect, useState } from 'react';
import { getOrderEvents } from '../../api/api.orders';
import { checkAPIError } from '../../services/ErrorService';
import { Box, Octicon, Timeline } from '@primer/react';
import { GitCommitIcon, Icon, IssueClosedIcon, IssueOpenedIcon } from '@primer/octicons-react';
import moment from 'moment';
import {
  findChangeByKey,
  getValueByKey,
  hasValue,
  isOldValueByKey,
  isValueByKey,
  keyExists
} from '../../utils/ChangesetParser';
import { EventData, Changeset } from 'kiisu-api-types/core.orders';
import { Banner } from '@primer/react/experimental';

interface Activity {
  id: string;
  icon: Icon;
  date: string;
  lines: string[];
}

function OrderActivityLog(props: { orderId: string; update: boolean }) {
  const [apiError, setApiError] = useState(undefined as string | undefined);
  const [activityList, setActivityList] = useState(new Array<Activity>());

  function handleError(error: any) {
    setApiError(checkAPIError(error));
    if (error) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  useEffect(() => {
    const getEvents = async () => {
      const { data } = (await getOrderEvents(props.orderId)) as { data: EventData[] };
      // Do not process events where nothing is updated, except DELETE actions.
      const filteredEvents = data.filter((r: EventData) => (r.diff && r.diff.length > 0) || r.a === 'DELETE');
      processEventData(filteredEvents);
    };

    // Make sure orderId has been set and users have been loaded
    if (props.orderId) {
      getEvents().catch((error) => handleError(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.orderId, props.update]);

  function processEventData(rows: EventData[]) {
    let list: Activity[] = [];

    rows.forEach((r) => {
      const activity: Activity = {
        id: r.id,
        date: getActionDate(r),
        icon: getActionSign(r),
        lines: getDescriptions(r)
      };
      list.push(activity);
    });

    setActivityList(list);
  }

  function getActionDate(event: EventData) {
    if (isOrderClosedEvent(event) && keyExists(event.diff, 'completed')) {
      return getValueByKey(event.diff, 'completed');
    }

    return event.dt;
  }

  function getActionSign(event: EventData) {
    if (isNewOrderEvent(event)) {
      return IssueOpenedIcon;
    }

    if (isOrderClosedEvent(event) || isOrderCanceledEvent(event)) {
      return IssueClosedIcon;
    }

    return GitCommitIcon;
  }

  function getDescriptions(event: EventData): string[] {
    let result: string[] = [];

    if (isNewOrderEvent(event)) {
      result.push(`${event.u.name} lisas uue tellimuse`);
    }

    if (isAddedAttachmentEvent(event)) {
      result.push(`${event.u.name} lisas manuse ${getValueByKey(event.diff, 'filename')}`);
    }

    if (isRemovedAttachmentEvent(event)) {
      result.push(`${event.u.name} eemaldas manuse ${getValueByKey(event.diff, 'filename')}`);
    }

    if (isSignatureRemoved(event.diff)) {
      result.push(`${event.u.name} tühistas kliendi allkirja`);
    }

    if (isSignatureAdded(event.diff)) {
      const client = getValueByKey(event.diff, 'clientName');

      if (client) {
        result.push(`${client} allkirjastas tellimuse`);
      } else {
        result.push('Tellimusele lisati allkiri'); // Order has been signed without client name
      }
    }

    if (isOrderStatusChange(event)) {
      // Order has been reopened after previously being closed or signed
      if (isOrderReopenEvent(event.diff)) {
        result.push(`${event.u.name} võttis tellimuse uuesti töösse`);
      }
      // Order has been taken into work
      if (isValueByKey(event.diff, 'status', 'inProgress') && !isOrderReopenEvent(event.diff)) {
        result.push(`${event.u.name} võttis tellimuse töösse`);
      }
      // Order work has been paused
      if (isValueByKey(event.diff, 'status', 'paused') && isOldValueByKey(event.diff, 'status', 'inProgress')) {
        result.push(`${event.u.name} peatas tellimuse töö`);
      }
      // Order work is done
      if (isValueByKey(event.diff, 'status', 'done')) {
        result.push(`${event.u.name} lõpetas tellimuse`);
      }
      // Order has been canceled
      if (isValueByKey(event.diff, 'status', 'canceled')) {
        result.push(`${event.u.name} tühistas tellimuse`);
      }
    }

    if (isOrderDataChange(event)) {
      result.push(`${event.u.name} muutis tellimuse andmeid`);
    }

    if (event.a === 'INSERT' && typeMap[event.t]) {
      result.push(`${event.u.name} lisas ${typeMap[event.t]}`);
    }

    if (event.a === 'UPDATE' && typeMap[event.t]) {
      result.push(`${event.u.name} uuendas ${typeMap[event.t]} andmeid`);
    }

    if (event.a === 'DELETE' && typeMap[event.t]) {
      result.push(`${event.u.name} kustutas ${typeMap[event.t]}`);
    }

    return result;
  }

  function isNewOrderEvent(event: EventData): boolean {
    return event.a === 'INSERT' && isOrderEvent(event.t);
  }

  function isOrderClosedEvent(event: EventData): boolean {
    return event.a === 'UPDATE' && isOrderEvent(event.t) && isValueByKey(event.diff, 'status', 'done');
  }

  function isOrderCanceledEvent(event: EventData): boolean {
    return event.a === 'UPDATE' && isOrderEvent(event.t) && isValueByKey(event.diff, 'status', 'canceled');
  }

  function isAddedAttachmentEvent(event: EventData): boolean {
    return event.a === 'INSERT' && event.t === 'attachment';
  }

  function isRemovedAttachmentEvent(event: EventData): boolean {
    return event.a === 'DELETE' && event.t === 'attachment';
  }

  function isOrderStatusChange(event: EventData) {
    return isOrderEvent(event.t) && event.a === 'UPDATE' && keyExists(event.diff, 'status');
  }

  function isOrderReopenEvent(diff: Changeset[]) {
    return (
      isValueByKey(diff, 'status', 'inProgress') &&
      (isOldValueByKey(diff, 'status', 'done') || isOldValueByKey(diff, 'status', 'signed'))
    );
  }

  function isSignatureAdded(diff: Changeset[]) {
    return hasValue(diff, 'clientSignature');
  }

  function isSignatureRemoved(diff: Changeset[]) {
    const cs = findChangeByKey(diff, 'clientSignature');
    return cs && !cs.value && cs.oldValue;
  }

  function isOrderDataChange(event: EventData) {
    return event.a === 'UPDATE' && isOrderEvent(event.t) && !isOrderStatusChange(event);
  }

  function isOrderEvent(type: string): boolean {
    return type === 'Order' || type === 'order';
  }

  const typeMap: { [key: string]: string } = {
    WorkProgress: 'töötamise',
    DrivingData: 'sõiduinfo',
    StockItem: 'materjali',
    UsedSpare: 'varuosa',
    workProgress: 'töötamise',
    drivingData: 'sõiduinfo',
    stockItem: 'materjali'
  };

  return (
    <Box px={2} py={3}>
      {apiError && (
        <Banner style={{ padding: '0.75rem 0.5rem', marginBottom: '1rem' }} variant="critical" title={apiError} />
      )}
      {activityList.length > 0 && (
        <Timeline>
          {activityList.map((a) => {
            return (
              <Timeline.Item key={a.id}>
                <Timeline.Badge>
                  <Octicon icon={a.icon} />
                </Timeline.Badge>
                <Timeline.Body>
                  <ActivityBody date={a.date} lines={a.lines} />
                </Timeline.Body>
              </Timeline.Item>
            );
          })}
        </Timeline>
      )}
    </Box>
  );
}

export default OrderActivityLog;

function ActivityBody(props: { date: string; lines: string[] }) {
  const dt = moment(props.date).format('DD.MM.YYYY HH:mm:ss');

  return (
    <Box>
      <p>{dt}</p>
      {props.lines.map((l, index) => (
        <p key={index}>{l}</p>
      ))}
    </Box>
  );
}
