import React from 'react';
import { Dialog } from '@primer/react';
import WorkForm from './WorkForm';
import UsedSpareForm from './UsedSpareForm';
import DrivingForm from './DrivingForm';
import StockForm from './StockForm';
import { CloseDialog } from '../../types/common';

function WorkDataDialog(props: {
  show: boolean;
  id?: string;
  type: string;
  orderId: string;
  closeDialog: CloseDialog;
}) {
  function getTitle(): string | undefined {
    switch (props.type) {
      case 'WorkProgress':
        return 'Töötamise sisestamine';
      case 'StockItem':
        return 'Materjali sisestamine';
      case 'UsedSpare':
        return 'Varuosa sisestamine';
      case 'DrivingData':
        return 'Sõiduinfo sisestamine';
    }
  }

  return (
    <Dialog isOpen={props.show} onDismiss={props.closeDialog}>
      <Dialog.Header>{getTitle()}</Dialog.Header>

      {props.type === 'WorkProgress' && (
        <WorkForm id={props.id} orderId={props.orderId} closeModal={props.closeDialog} />
      )}

      {props.type === 'StockItem' && <StockForm id={props.id} orderId={props.orderId} closeModal={props.closeDialog} />}

      {props.type === 'UsedSpare' && (
        <UsedSpareForm id={props.id} orderId={props.orderId} closeModal={props.closeDialog} />
      )}

      {props.type === 'DrivingData' && (
        <DrivingForm
          id={props.id}
          orderId={props.orderId}
          closeModal={props.closeDialog}
        />
      )}
    </Dialog>
  );
}

export default WorkDataDialog;
