import styled, { css } from 'styled-components';
import { sx, SxProp, themeGet } from '@primer/react';
import { IconProps } from '@primer/octicons-react';

/*
Componentization of https://github.com/primer/css/blob/v19.8.2/src/blankslate/blankslate.scss

https://primer.style/css/components/blankslate
*/

const Blankslate = styled.div<{ large?: boolean; spacious?: boolean }>`
  position: relative;
  padding: 32px;
  text-align: center;

  p {
    color: ${themeGet('colors.fg.muted')};
  }

  img {
    width: 56px;
    height: 56px;
  }

  ${({ large }) =>
    large &&
    css`
      img {
        width: 80px;
        height: 80px;
      }

      h3 {
        margin: 16px 0;
        font-size: 24px;
      }

      p {
        font-size: 16px;
      }
    `};
  ${({ spacious }) =>
    spacious &&
    css`
      padding: 80px 40px;
    `};
`;

const BlankslateHeading = styled.h3<SxProp>`
  margin-bottom: 4px;
  ${sx}
`;

const BlankslateImage = styled.img<SxProp>`
  margin-bottom: 16px;
  ${sx}
`;

type OcticonProps = { icon: React.ElementType; color?: string } & IconProps;

function Octicon({ icon: IconComponent, ...rest }: OcticonProps) {
  return <IconComponent {...rest} />;
}

const BlankslateIcon = styled(Octicon)<SxProp>`
  margin-right: 4px;
  margin-bottom: 8px;
  margin-left: 4px;
  color: ${themeGet('colors.fg.muted')};
`;

const BlankslateAction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  &:first-of-type {
    margin-top: 24px;
  }

  &:last-of-type {
    margin-bottom: 8px;
  }
`;

export { Blankslate, BlankslateHeading, BlankslateImage, BlankslateIcon, BlankslateAction };
